import z from "zod";
import i18next from "../../i18n/i18n";
import { isoToDateTime } from "oneclick-component/src/utils/datetime";

const ensureStartTimeBeforeEndTime = (
  timeRanges: {
    startTime: {
      hour: number;
      minute: number;
    };
    endTime: {
      hour: number | null;
      minute: number | null;
    };
  }[]
) => {
  for (const time of timeRanges) {
    const { startTime, endTime } = time;
    if (endTime.hour == null || endTime.minute == null) {
      continue;
    }

    if (endTime.hour === startTime.hour && endTime.minute <= startTime.minute) {
      return false;
    }

    if (endTime.hour < startTime.hour) return false;
  }
  return true;
};

const ensureNoOverlapTime = (
  timeRanges: {
    startTime: {
      hour: number;
      minute: number;
    };
    endTime: {
      hour: number | null;
      minute: number | null;
    };
  }[]
) => {
  const timeRangesSecond: { start: number; end: number }[] = [];
  for (const time of timeRanges) {
    const { startTime, endTime } = time;
    const start = startTime.hour * 60 + startTime.minute;
    const end = (endTime.hour ?? 23) * 60 + (endTime.minute ?? 45);

    for (const timeRange of timeRangesSecond) {
      if (!(timeRange.start >= end || timeRange.end <= start)) {
        return false;
      }
    }

    timeRangesSecond.push({ start, end });
  }

  return true;
};

const ensureRecurringStartDateBeforeEndDate = (
  arg:
    | { recurringType: "single"; date: string }
    | {
        recurringType: "repeat";
        weekdays: boolean[];
        startDate: string;
        endDate: string;
      }
    | { recurringType: "custom"; dates: { date: string }[] }
) => {
  if (arg.recurringType !== "repeat") {
    return true;
  }
  const { startDate, endDate } = arg;
  const start = isoToDateTime(startDate);
  const end = isoToDateTime(endDate);
  return start <= end;
};

const checkCCOShiftSetting = (arg: {
  ccoRateId?: number;
  rate: number;
  ptType: string[];
}) => {
  const { ccoRateId, rate, ptType } = arg;
  if (ccoRateId == null) {
    return true;
  }

  if (
    (ccoRateId === rate && !ptType.includes("CCO")) ||
    (ccoRateId !== rate && ptType.includes("CCO"))
  ) {
    return false;
  }

  return true;
};

const checkCCOEndTime = (arg: {
  ptType: string[];
  timeRanges: {
    startTime: {
      hour: number;
      minute: number;
    };
    endTime: {
      hour: number | null;
      minute: number | null;
    };
  }[];
}) => {
  const { ptType, timeRanges } = arg;
  if (ptType.includes("CCO")) {
    for (const range of timeRanges) {
      if (range.endTime.hour == null || range.endTime.minute == null) {
        return false;
      }
    }
  }

  return true;
};

const singleDateRecurringOption = z.object({
  recurringType: z.literal("single"),
  date: z.string().min(1),
});
const repeatDateRecurringOption = z.object({
  recurringType: z.literal("repeat"),
  weekdays: z.array(z.boolean()),
  startDate: z.string().min(1),
  endDate: z.string().min(1),
});
const customDateRecurringOption = z.object({
  recurringType: z.literal("custom"),
  dates: z
    .array(
      // `useFieldArray` only works with object
      z.object({
        date: z.string().min(1),
      })
    )
    .min(1),
});

const recurringOptions = z.lazy(() =>
  z
    .discriminatedUnion("recurringType", [
      singleDateRecurringOption,
      repeatDateRecurringOption,
      customDateRecurringOption,
    ])
    .refine(ensureRecurringStartDateBeforeEndDate, {
      message: i18next.t(
        "shift.create.form.error.recurringEndDateBeforeStartDate"
      ),
      path: ["endDate"],
    })
);

export type RecurringOptionsType = z.infer<typeof recurringOptions>;

export const multiShiftFormSchema = z.lazy(() =>
  z
    .object({
      stationId: z.number().int(),
      supportStationId: z.number().int().nullable(),
      rate: z.number().int(),
      ccoRateId: z.number().int().optional(),
      shiftType: z.enum(["INCIDENT", "REGULAR"]),
      ptType: z.array(z.string()).min(1),
      fulfillmentCount: z.preprocess(
        (value) => (value === "" ? undefined : Number(value)),
        z
          .number()
          .int()
          .min(1)
          .max(Math.pow(2, 31) - 1)
      ),
      recurringOptions: recurringOptions,
      timeRanges: z
        .array(
          z.object({
            startTime: z.object({
              hour: z.number(),
              minute: z.number(),
            }),
            endTime: z.object({
              hour: z.number().nullable(),
              minute: z.number().nullable(),
            }),
          })
        )
        .refine(ensureStartTimeBeforeEndTime, {
          message: i18next.t("shift.create.form.error.endTimeBeforeStartTime"),
        })
        .refine(ensureNoOverlapTime, {
          message: i18next.t("shift.create.form.error.overlapTime"),
        }),
      title: z.string().trim().min(1),
      description: z.string().trim().min(1),
      isAppliableToAllAtT3: z.boolean(),
    })
    .refine(checkCCOShiftSetting, {
      message: i18next.t("shift.create.form.error.ccoTypeMismatch"),
      path: ["ptType"],
    })
    .refine(checkCCOEndTime, {
      message: i18next.t("shift.create.form.error.ccoNeedEndTime"),
      path: ["timeRanges"],
    })
);

export type MultiShiftFormValues = z.infer<typeof multiShiftFormSchema>;
