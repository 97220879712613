import React, { useCallback, useMemo, useState } from "react";
import cn from "classnames";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Dialog } from "@headlessui/react";
import { Trans, useTranslation } from "react-i18next";
import {
  UserStationProfile,
  useValidateInviteHandlerUsersValidateInviteGetQuery,
  useInviteManagerToStationHandlerUsersInviteUserIdPostMutation as useInviteManager,
} from "oneclick-component/src/store/apis/enhancedApi";
import { Modal } from "oneclick-component/src/components/Modal";
import { isFetchQueryBaseError } from "oneclick-component/src/models/error";
import { Button } from "oneclick-component/src/components/Button";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";
import { StationBadge } from "oneclick-component/src/components/Badge";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import { FormTextInput } from "oneclick-component/src/components/forms";
import { useShowError } from "../../hooks/useShowError";
import { Form, formSchema } from "./form";

interface Props {
  onClose: () => void;
  isOpen: boolean;
  meUserSelectedStation: UserStationProfile | null;
}

const AddStationManagerDialog = (props: Props): React.ReactElement | null => {
  const { meUserSelectedStation, onClose, isOpen } = props;
  const [email, setEmail] = useState("");

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm<Form>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });

  const { t } = useTranslation();
  const { showError } = useShowError();
  const showMessage = useShowMessage();
  const [invite, { isLoading: isInviteLoading }] = useInviteManager();
  const { data, isLoading, error } =
    useValidateInviteHandlerUsersValidateInviteGetQuery(
      {
        email: email,
      },
      { skip: email === "" }
    );

  const emailErrorText: string | null = useMemo(() => {
    if (error != null && isFetchQueryBaseError(error)) {
      if (error.status === 404) {
        return t("addStationManagerDialog.error.emailNotFound");
      }

      if ((error.data as any).detail != null) {
        switch ((error.data as any).detail.description) {
          // TODO: change to check error code
          case "target user must be a station manager":
            return t("addStationManagerDialog.error.userNotManager");
          case "user already in this station":
            return t("addStationManagerDialog.error.alreadyExist");
        }
      }
    }
    return null;
  }, [error, t]);

  const isStep2Enabled = useMemo(() => {
    return (
      emailErrorText == null &&
      data != null &&
      email !== "" &&
      email === getValues().email
    );
  }, [emailErrorText, data, getValues, email]);

  const onClickInvite = useCallback(() => {
    if (data == null || meUserSelectedStation == null) {
      return;
    }
    invite({
      userId: data.user.id,
      inviteUserRequest: {
        stationId: meUserSelectedStation.station.id,
      },
    })
      .unwrap()
      .then(() => {
        showMessage({
          title: t("addStationManagerDialog.action.success.title"),
          message: t("addStationManagerDialog.action.success.description"),
          type: "success",
          showDismiss: true,
        });
        setEmail("");
        reset();
        onClose();
      })
      .catch((e) => {
        showError(e, t("shift.cancel.toast.fail.title"));
      });
  }, [
    meUserSelectedStation,
    data,
    t,
    onClose,
    showError,
    reset,
    invite,
    showMessage,
  ]);

  const handleOnClose = useCallback(() => {
    if (isLoading || isInviteLoading) {
      return;
    }
    onClose();
  }, [isLoading, isInviteLoading, onClose]);

  const _onSubmit = useCallback((data: Form) => {
    setEmail(data.email);
  }, []);

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handleSubmit(_onSubmit)(e).catch((err: unknown) => {
        throw err;
      });
      e.stopPropagation();
    },
    [handleSubmit, _onSubmit]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      hasXMarkButton={true}
      className="!w-100"
    >
      <div className={cn("sm:flex", "sm:items-start", "w-full")}>
        <div className={cn("text-center", "sm:mt-0", "sm:text-left", "w-full")}>
          <Dialog.Title
            className={cn(
              "text-black",
              "text-lg",
              "font-medium",
              "inline-flex",
              "items-center"
            )}
          >
            {t("addStationManagerDialog.title", {
              stationBadge:
                meUserSelectedStation != null ? (
                  <StationBadge
                    key="station"
                    className="mx-1"
                    station={meUserSelectedStation.station}
                    stationTeam={null}
                  />
                ) : null,
            })}
          </Dialog.Title>
          <div className="mt-8">
            <p className={cn("text-lg", "font-normal", "text-black")}>
              <Trans i18nKey="addStationManagerDialog.step1.title" />
            </p>
            <p className={cn("text-lg", "font-normal", "text-black")}>
              <Trans i18nKey="addStationManagerDialog.step1.description" />
            </p>
          </div>
          <div className="mt-6">
            <form onSubmit={onSubmit}>
              <FormTextInput
                name="email"
                control={control}
                label={t("addStationManagerDialog.step1.email")}
                placeholder={t(
                  "addStationManagerDialog.step1.emailPlaceholder"
                )}
                required={true}
                hasError={emailErrorText != null}
                errorMessage={emailErrorText ?? errors.email?.message}
                suffixDecorator={isLoading ? <LoadingSpinner /> : undefined}
              />
            </form>
          </div>
          <hr className="my-10" />
          <div>
            <p
              className={cn("text-lg", "font-normal", {
                "text-black/24": !isStep2Enabled,
                "text-black": isStep2Enabled,
              })}
            >
              <Trans i18nKey="addStationManagerDialog.step2.title" />
            </p>
            <p
              className={cn("text-lg", "font-normal", {
                "text-black/24": !isStep2Enabled,
                "text-black": isStep2Enabled,
              })}
            >
              <Trans i18nKey="addStationManagerDialog.step2.description" />
            </p>
          </div>
          <div className={cn("mt-6", "mb-8")}>
            <p
              className={cn("text-lg", "font-normal", {
                "text-black/24": !isStep2Enabled,
                "text-black/60": isStep2Enabled,
              })}
            >
              <Trans i18nKey="addStationManagerDialog.step2.nameZhHk" />
            </p>
            <p
              className={cn("text-lg", "font-normal", "mb-4", {
                "text-black/24": !isStep2Enabled,
                "text-black/86": isStep2Enabled,
              })}
            >
              {isStep2Enabled && data != null ? data.user.nameZhHk : "-"}
            </p>
            <p
              className={cn("text-lg", "font-normal", {
                "text-black/24": !isStep2Enabled,
                "text-black/60": isStep2Enabled,
              })}
            >
              <Trans i18nKey="addStationManagerDialog.step2.nameEn" />
            </p>
            <p
              className={cn("text-lg", "font-normal", {
                "text-black/24": !isStep2Enabled,
                "text-black/86": isStep2Enabled,
              })}
            >
              {isStep2Enabled && data != null ? data.user.name : "-"}
            </p>
          </div>
        </div>
      </div>
      <div
        className={cn(
          "mt-5",
          "sm:mt-4",
          "flex",
          "flex-row",
          "justify-center",
          "sm:justify-end",
          "items-center"
        )}
      >
        <Button
          className={cn(
            "font-medium",
            "flex-1",
            "self-stretch",
            "sm:flex-none"
          )}
          onClick={handleOnClose}
          theme="white"
        >
          <Trans i18nKey="addStationManagerDialog.action.cancel" />
        </Button>
        <Button
          className={cn(
            "font-medium",
            "ml-3",
            "flex-1",
            "sm:flex-none",
            "sm:min-w-30"
          )}
          disabled={isLoading || !isStep2Enabled}
          isLoading={isInviteLoading}
          onClick={onClickInvite}
        >
          <Trans i18nKey="addStationManagerDialog.action.addStationManager" />
        </Button>
      </div>
    </Modal>
  );
};

export default AddStationManagerDialog;
