import {
  ReactElement,
  useCallback,
  useMemo,
  useState,
  useContext,
} from "react";
import { Trans, useTranslation } from "react-i18next";
import cn from "classnames";
import { PlusIcon } from "@heroicons/react/24/outline";
import Slider from "oneclick-component/src/components/Slider";
import { Button } from "oneclick-component/src/components/Button";
import { SendIcon } from "oneclick-component/src/icon";
import {
  MasterShiftDetail,
  ShiftStationFilterType,
  useSendMasterShiftInvitationHandlerMasterShiftsMasterShiftIdInvitePostMutation as useBatchSend,
  useListByShiftHandlerStationsListByShiftShiftIdGetQuery as useListStations,
  useGetAvailablePtUserByStationsHandlerMasterShiftsAvailablePtUsersByStationsGetQuery as useListPtUsers,
  useGetMasterShiftRequestCountHandlerMasterShiftsMasterShiftIdRequestCountGetQuery as useMasterShiftRequestCount,
} from "oneclick-component/src/store/apis/enhancedApi";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";
import { ConfirmSendAllStationsDialog } from "../../../components/ConfirmSendAllStationsDialog";
import { FeatureConfigContext } from "../../../providers/FeatureConfigProvider";
import { useShowError } from "../../../hooks/useShowError";
import { useSelectedStationProfile } from "../../../hooks/useSelectedStationProfile";
import useMeUser from "../../../hooks/useMeUser";
import FindPTUserStationSelect, {
  LineStations,
} from "../../ShiftDetailScreen/FindPTUserStationSelect";

interface SendInvitationButtonProps {
  userIds: number[];
  handleSendAll: () => void;
  stationGroupIndex: number;
  enableAllStations: boolean;
  isLoadAllStations: boolean;
  isBatchSendLoading: boolean;
  isPartTimeListLoading: boolean;
}

const SendInvitationButton = (props: SendInvitationButtonProps) => {
  const {
    handleSendAll,
    userIds,
    stationGroupIndex,
    enableAllStations,
    isLoadAllStations,
    isBatchSendLoading,
    isPartTimeListLoading,
  } = props;
  return (
    <Button
      className={cn(
        "flex-1",
        "min-h-10.5",
        "sm:mt-0",
        "self-end",
        "lg:flex-none"
      )}
      prefixIcon={SendIcon}
      theme="primary"
      onClick={handleSendAll}
      disabled={
        userIds.length === 0 ||
        (stationGroupIndex === 3 &&
          (!enableAllStations || !isLoadAllStations)) ||
        isPartTimeListLoading
      }
      isLoading={isBatchSendLoading || isPartTimeListLoading}
    >
      <span className={cn("mx-auto", "sm:mx-0")}>
        <Trans
          i18nKey={
            userIds.length === 0
              ? "shiftDetail.action.masterSendAll.noSelection"
              : "shiftDetail.action.masterSendAll"
          }
        />
      </span>
    </Button>
  );
};

const filterType: Array<ShiftStationFilterType> = [
  "home",
  "relative",
  "line",
  "all",
];

interface Props {
  masterShift: MasterShiftDetail;
  defaultExpand: boolean;
}

const InvitationSection = (props: Props): ReactElement => {
  const { defaultExpand, masterShift } = props;
  const meUserSelectedStation = useSelectedStationProfile();
  const meUser = useMeUser();

  const { t } = useTranslation();
  const { showError } = useShowError();
  const showMessage = useShowMessage();
  const [showStationSelect, setShowStationSelect] = useState(defaultExpand);
  const [stationGroupIndex, setStationGroupIndex] = useState(0);
  const [isLoadAllStations, setIsLoadAllStations] = useState(false);
  const [sendConfirmDialog, setSendConfirmDialog] = useState(false);
  const { allStationsFilterEnabledStations } = useContext(FeatureConfigContext);

  const enableAllStations = useMemo(() => {
    if (meUser?.adminRole === "ADMIN") {
      return true;
    }
    if (meUserSelectedStation?.station.shortCode == null) {
      return false;
    }
    return (
      meUserSelectedStation.station.shortCode !== "" &&
      allStationsFilterEnabledStations.includes(
        meUserSelectedStation.station.shortCode
      )
    );
  }, [meUserSelectedStation, allStationsFilterEnabledStations, meUser]);

  const [selectedStationIds, setSelectedStationIds] = useState<number[]>([
    masterShift.workingStation.mainStationId,
  ]);

  const { data: totalSentCount } = useMasterShiftRequestCount({
    masterShiftId: masterShift.id,
  });

  const shiftId = useMemo(() => {
    return masterShift.shifts[0].id;
  }, [masterShift]);

  const { data: stationsResult, isLoading: isStationLoading } = useListStations(
    {
      shiftId: shiftId,
      filterType: filterType[stationGroupIndex],
    },
    {
      skip:
        stationGroupIndex === 3 && (!enableAllStations || !isLoadAllStations),
    }
  );

  const groupedStations: LineStations[] = useMemo(() => {
    if (stationsResult?.stations == null) {
      return [];
    }
    const lineMap: Record<number, LineStations | undefined> = {};
    let orderedStations = [...stationsResult.stations];
    orderedStations = orderedStations.sort((a, b) => {
      return a.shortCode > b.shortCode ? 1 : -1;
    });

    for (const station of orderedStations) {
      const line = station.lines[0];
      if (lineMap[line.id] == null) {
        lineMap[line.id] = {
          line: line,
          stations: [station],
        };
      } else {
        lineMap[line.id]?.stations.push(station);
      }
    }
    return Object.values(lineMap) as LineStations[];
  }, [stationsResult]);

  const { data: partTimeList, isFetching: isPartTimeListLoading } =
    useListPtUsers({
      stationIds: selectedStationIds,
      showAll: true,
      ptRoleIds: masterShift.ptUserRoles?.map((role) => role.id),
      masterShiftId: masterShift.id,
    });

  const defaultSelectedStationIds = useMemo(() => {
    if (stationsResult?.stations == null) {
      return [];
    }
    const stationIds = stationsResult.stations.map((st) => st.id);
    return stationIds;
  }, [stationsResult]);

  const [sendBatch, { isLoading: isBatchSendLoading }] = useBatchSend();

  const handleHidePtUserList = useCallback(() => {
    setShowStationSelect(false);
  }, []);

  const handleShowStationSelect = useCallback(() => {
    setShowStationSelect(true);
  }, []);

  const userIds = useMemo(() => {
    if (partTimeList?.ptUserIds == null) {
      return [];
    }
    return partTimeList.ptUserIds;
  }, [partTimeList]);

  const slideLabels = useMemo(() => {
    return [
      t("shiftDetail.stationGroup.home"),
      t("shiftDetail.stationGroup.group"),
      t("shiftDetail.stationGroup.line"),
      t("shiftDetail.stationGroup.all"),
    ];
  }, [t]);

  const handleSliderChange = useCallback(
    (e: number) => {
      if (e === 3 && enableAllStations) {
        setIsLoadAllStations(true);
      }
      setStationGroupIndex(e);
    },
    [enableAllStations]
  );

  const handleSendAll = useCallback(() => {
    const type = filterType[stationGroupIndex];
    sendBatch({
      masterShiftId: masterShift.id,
      sendMasterShiftInvitationRequest: {
        ptUserIds: userIds,
        stationIds: selectedStationIds,
      },
    })
      .unwrap()
      .then(() => {
        showMessage({
          type: "success",
          title: t("shiftDetail.batchRequest.success.title"),
          message: t("shiftDetail.batchRequest.success.message", {
            count: userIds.length,
            typeText: t(`shiftDetail.batchRequest.success.stationType.${type}`),
          }),
        });
        handleHidePtUserList();
        setStationGroupIndex(0);
      })
      .catch((err: unknown) => {
        showError(err, "shiftDetail.request.sent.fail.title");
      });
  }, [
    stationGroupIndex,
    sendBatch,
    masterShift.id,
    userIds,
    selectedStationIds,
    showMessage,
    t,
    handleHidePtUserList,
    showError,
  ]);

  const handleConfirmSendAll = useCallback(() => {
    if (stationGroupIndex !== 3) {
      handleSendAll();
      return;
    }
    setSendConfirmDialog(true);
  }, [handleSendAll, stationGroupIndex]);

  return (
    <div className={cn("max-w-169", "mx-auto")}>
      <ConfirmSendAllStationsDialog
        isOpen={sendConfirmDialog}
        isIncident={masterShift.isIncident}
        isSelectedAllStations={
          selectedStationIds.length === defaultSelectedStationIds.length
        }
        setShowDialog={setSendConfirmDialog}
        onSend={handleSendAll}
      />
      {!showStationSelect ? (
        <>
          <section
            className={cn(
              "rounded-lg",
              "bg-white",
              "shadow",
              "mx-auto",
              "mb-5"
            )}
          >
            <div className="p-6">
              <div className={cn("flex", "flex-col", "gap-5")}>
                <p className={cn("font-medium", "text-lg")}>
                  <Trans i18nKey="shiftDetail.title" />
                </p>
                <p className="text-sm">
                  <Trans
                    i18nKey="shiftDetail.action.masterSentCount"
                    values={{
                      count: totalSentCount?.count ?? 0,
                    }}
                  />
                </p>
              </div>
            </div>
          </section>
          <div
            className={cn(
              "md:px-0",
              "px-4",
              "w-full",
              "text-right",
              "mx-auto",
              "mb-5"
            )}
          >
            <Button
              theme="primary"
              className={cn("w-full", "md:w-auto")}
              prefixIcon={PlusIcon}
              onClick={handleShowStationSelect}
            >
              <Trans i18nKey="shiftDetail.showSearchButton" />
            </Button>
          </div>
        </>
      ) : null}
      {showStationSelect ? (
        <section className={cn("rounded-lg", "bg-white", "shadow", "mx-auto")}>
          <div className="p-6">
            <div className={cn("flex", "flex-col", "gap-5")}>
              <div className={cn("flex", "flex-row")}>
                <p
                  className={cn("font-medium", "text-lg", "flex-1", "md:grow")}
                >
                  <Trans i18nKey="shiftDetail.title" />
                </p>
                <SendInvitationButton
                  handleSendAll={handleConfirmSendAll}
                  userIds={userIds}
                  stationGroupIndex={stationGroupIndex}
                  enableAllStations={enableAllStations}
                  isLoadAllStations={isLoadAllStations}
                  isBatchSendLoading={isBatchSendLoading}
                  isPartTimeListLoading={isPartTimeListLoading}
                />
              </div>
              <div className={cn("w-4/5", "mx-auto", "mt-6")}>
                <Slider
                  labels={slideLabels}
                  min={0}
                  max={3}
                  onChange={handleSliderChange}
                  labelClassName="w-1/4"
                  className={cn("pb-20", "md:pb-2")}
                />
              </div>
              {stationGroupIndex === 3 && !enableAllStations ? (
                <p>
                  <Trans i18nKey="common.comingSoon" />
                </p>
              ) : (
                <div>
                  <FindPTUserStationSelect
                    onUpdateSelectStations={setSelectedStationIds}
                    isLoading={isStationLoading}
                    defaultStationIds={defaultSelectedStationIds}
                    lineStations={groupedStations}
                    shouldShow={true}
                  />
                </div>
              )}
            </div>
          </div>
        </section>
      ) : null}
    </div>
  );
};

export default InvitationSection;
