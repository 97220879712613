import { baseSplitApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    listAdminAppConfigHandlerAdminAppConfigGet: build.query<
      ListAdminAppConfigHandlerAdminAppConfigGetApiResponse,
      ListAdminAppConfigHandlerAdminAppConfigGetApiArg
    >({
      query: () => ({ url: `/admin-app-config` }),
    }),
    createAnnouncementHandlerAnnouncementsPost: build.mutation<
      CreateAnnouncementHandlerAnnouncementsPostApiResponse,
      CreateAnnouncementHandlerAnnouncementsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/announcements`,
        method: "POST",
        body: queryArg.createAnnouncementRequest,
      }),
    }),
    listAnnouncementHandlerAnnouncementsListGet: build.query<
      ListAnnouncementHandlerAnnouncementsListGetApiResponse,
      ListAnnouncementHandlerAnnouncementsListGetApiArg
    >({
      query: (queryArg) => ({
        url: `/announcements/list`,
        params: {
          pageIndex: queryArg.pageIndex,
          pageSize: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    getAnnouncementByIdHandlerAnnouncementsAnnouncementIdGet: build.query<
      GetAnnouncementByIdHandlerAnnouncementsAnnouncementIdGetApiResponse,
      GetAnnouncementByIdHandlerAnnouncementsAnnouncementIdGetApiArg
    >({
      query: (queryArg) => ({
        url: `/announcements/${queryArg.announcementId}`,
      }),
    }),
    authenticateHandlerAuthenticateGet: build.query<
      AuthenticateHandlerAuthenticateGetApiResponse,
      AuthenticateHandlerAuthenticateGetApiArg
    >({
      query: (queryArg) => ({
        url: `/authenticate`,
        params: { msalAccessToken: queryArg.msalAccessToken },
      }),
    }),
    listMessageHandlerChatsChatIdMessagesGet: build.query<
      ListMessageHandlerChatsChatIdMessagesGetApiResponse,
      ListMessageHandlerChatsChatIdMessagesGetApiArg
    >({
      query: (queryArg) => ({
        url: `/chats/${queryArg.chatId}/messages`,
        params: {
          direction: queryArg.direction,
          before: queryArg.before,
          after: queryArg.after,
          limit: queryArg.limit,
        },
      }),
    }),
    getUnreadChatCountHandlerChatsUnreadChatCountGet: build.query<
      GetUnreadChatCountHandlerChatsUnreadChatCountGetApiResponse,
      GetUnreadChatCountHandlerChatsUnreadChatCountGetApiArg
    >({
      query: () => ({ url: `/chats/unread-chat-count` }),
    }),
    getChatHandlerChatsChatIdGet: build.query<
      GetChatHandlerChatsChatIdGetApiResponse,
      GetChatHandlerChatsChatIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/chats/${queryArg.chatId}` }),
    }),
    listChatHandlerChatsGet: build.query<
      ListChatHandlerChatsGetApiResponse,
      ListChatHandlerChatsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/chats`,
        params: {
          q: queryArg.q,
          stationId: queryArg.stationId,
          exclude_suspended_pt_users: queryArg.excludeSuspendedPtUsers,
          before: queryArg.before,
          limit: queryArg.limit,
        },
      }),
    }),
    markChatReadHandlerChatsChatIdMarkReadPost: build.mutation<
      MarkChatReadHandlerChatsChatIdMarkReadPostApiResponse,
      MarkChatReadHandlerChatsChatIdMarkReadPostApiArg
    >({
      query: (queryArg) => ({
        url: `/chats/${queryArg.chatId}/mark-read`,
        method: "POST",
      }),
    }),
    sendTemplateMessageHandlerChatsChatIdTemplateMessagePost: build.mutation<
      SendTemplateMessageHandlerChatsChatIdTemplateMessagePostApiResponse,
      SendTemplateMessageHandlerChatsChatIdTemplateMessagePostApiArg
    >({
      query: (queryArg) => ({
        url: `/chats/${queryArg.chatId}/template-message`,
        method: "POST",
        body: queryArg.sendTemplateMessageRequest,
      }),
    }),
    sendTextMessageHandlerChatsChatIdTextMessagePost: build.mutation<
      SendTextMessageHandlerChatsChatIdTextMessagePostApiResponse,
      SendTextMessageHandlerChatsChatIdTextMessagePostApiArg
    >({
      query: (queryArg) => ({
        url: `/chats/${queryArg.chatId}/text-message`,
        method: "POST",
        body: queryArg.sendTextMessageRequest,
      }),
    }),
    syncConversationParticipantHandlerCronSyncConversationParticipantPost:
      build.mutation<
        SyncConversationParticipantHandlerCronSyncConversationParticipantPostApiResponse,
        SyncConversationParticipantHandlerCronSyncConversationParticipantPostApiArg
      >({
        query: (queryArg) => ({
          url: `/cron/sync-conversation-participant`,
          method: "POST",
          headers: {
            "x-mtr-oneclick-master-api-key":
              queryArg["x-mtr-oneclick-master-api-key"],
          },
        }),
      }),
    apiHealthCheckHandlerApiHealthCheckGet: build.query<
      ApiHealthCheckHandlerApiHealthCheckGetApiResponse,
      ApiHealthCheckHandlerApiHealthCheckGetApiArg
    >({
      query: () => ({ url: `/api-health-check` }),
    }),
    healthCheckHandlerHealthCheckGet: build.query<
      HealthCheckHandlerHealthCheckGetApiResponse,
      HealthCheckHandlerHealthCheckGetApiArg
    >({
      query: () => ({ url: `/health-check` }),
    }),
    getMeHandlerMeGet: build.query<
      GetMeHandlerMeGetApiResponse,
      GetMeHandlerMeGetApiArg
    >({
      query: () => ({ url: `/me` }),
    }),
    updateLanguagePreferenceHandlerMeLanguagePreferencePost: build.mutation<
      UpdateLanguagePreferenceHandlerMeLanguagePreferencePostApiResponse,
      UpdateLanguagePreferenceHandlerMeLanguagePreferencePostApiArg
    >({
      query: (queryArg) => ({
        url: `/me/language-preference`,
        method: "POST",
        body: queryArg.updateLanguagePreferenceRequest,
      }),
    }),
    switchProfileHandlerMeSwitchProfilePost: build.mutation<
      SwitchProfileHandlerMeSwitchProfilePostApiResponse,
      SwitchProfileHandlerMeSwitchProfilePostApiArg
    >({
      query: (queryArg) => ({
        url: `/me/switch-profile`,
        method: "POST",
        body: queryArg.meSwitchProfileRequest,
      }),
    }),
    revokeTokenHandlerMeRevokeTokenPost: build.mutation<
      RevokeTokenHandlerMeRevokeTokenPostApiResponse,
      RevokeTokenHandlerMeRevokeTokenPostApiArg
    >({
      query: () => ({ url: `/me/revoke-token`, method: "POST" }),
    }),
    deletePtUserWorkingStatusRecordHandlerPartTimeUsersPtUserIdWorkingStatusRecordDelete:
      build.mutation<
        DeletePtUserWorkingStatusRecordHandlerPartTimeUsersPtUserIdWorkingStatusRecordDeleteApiResponse,
        DeletePtUserWorkingStatusRecordHandlerPartTimeUsersPtUserIdWorkingStatusRecordDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/part-time-users/${queryArg.ptUserId}/working-status-record`,
          method: "DELETE",
          params: { year: queryArg.year, weekNumber: queryArg.weekNumber },
        }),
      }),
    upsertPtUserWorkingStatusRecordHandlerPartTimeUsersPtUserIdWorkingStatusRecordPost:
      build.mutation<
        UpsertPtUserWorkingStatusRecordHandlerPartTimeUsersPtUserIdWorkingStatusRecordPostApiResponse,
        UpsertPtUserWorkingStatusRecordHandlerPartTimeUsersPtUserIdWorkingStatusRecordPostApiArg
      >({
        query: (queryArg) => ({
          url: `/part-time-users/${queryArg.ptUserId}/working-status-record`,
          method: "POST",
          body: queryArg.upsertPtUserWorkingStatusRecordRequest,
        }),
      }),
    listPtUserWorkingStatusRecordHandlerPartTimeUsersWorkingStatusRecordsPost:
      build.query<
        ListPtUserWorkingStatusRecordHandlerPartTimeUsersWorkingStatusRecordsPostApiResponse,
        ListPtUserWorkingStatusRecordHandlerPartTimeUsersWorkingStatusRecordsPostApiArg
      >({
        query: (queryArg) => ({
          url: `/part-time-users/working-status-records`,
          method: "POST",
          body: queryArg.listPtUserWorkingStatusRecordRequest,
          params: { sort: queryArg.sort },
        }),
      }),
    createPtUserHandlerPartTimeUsersPost: build.mutation<
      CreatePtUserHandlerPartTimeUsersPostApiResponse,
      CreatePtUserHandlerPartTimeUsersPostApiArg
    >({
      query: (queryArg) => ({
        url: `/part-time-users`,
        method: "POST",
        body: queryArg.createPtUserRequest,
      }),
    }),
    createPtUserStationHandlerPartTimeUsersStationsPost: build.mutation<
      CreatePtUserStationHandlerPartTimeUsersStationsPostApiResponse,
      CreatePtUserStationHandlerPartTimeUsersStationsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/part-time-users/stations`,
        method: "POST",
        body: queryArg.createPtUserStationRequest,
      }),
    }),
    checkEidHandlerPartTimeUsersCheckEidGet: build.query<
      CheckEidHandlerPartTimeUsersCheckEidGetApiResponse,
      CheckEidHandlerPartTimeUsersCheckEidGetApiArg
    >({
      query: (queryArg) => ({
        url: `/part-time-users/check-eid`,
        params: { eid: queryArg.eid },
      }),
    }),
    deletePtUserHandlerPartTimeUsersPtUserIdDelete: build.mutation<
      DeletePtUserHandlerPartTimeUsersPtUserIdDeleteApiResponse,
      DeletePtUserHandlerPartTimeUsersPtUserIdDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/part-time-users/${queryArg.ptUserId}`,
        method: "DELETE",
      }),
    }),
    getPtUserByIdHandlerPartTimeUsersPtUserIdGet: build.query<
      GetPtUserByIdHandlerPartTimeUsersPtUserIdGetApiResponse,
      GetPtUserByIdHandlerPartTimeUsersPtUserIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/part-time-users/${queryArg.ptUserId}` }),
    }),
    updatePtUserHandlerPartTimeUsersPtUserIdPost: build.mutation<
      UpdatePtUserHandlerPartTimeUsersPtUserIdPostApiResponse,
      UpdatePtUserHandlerPartTimeUsersPtUserIdPostApiArg
    >({
      query: (queryArg) => ({
        url: `/part-time-users/${queryArg.ptUserId}`,
        method: "POST",
        body: queryArg.updatePtUserRequest,
      }),
    }),
    getPtUsersRolesHandlerPartTimeUsersRolesGet: build.query<
      GetPtUsersRolesHandlerPartTimeUsersRolesGetApiResponse,
      GetPtUsersRolesHandlerPartTimeUsersRolesGetApiArg
    >({
      query: () => ({ url: `/part-time-users/roles` }),
    }),
    getCountByStationGroupsHandlerPartTimeUsersGetCountByStationGroupsGet:
      build.query<
        GetCountByStationGroupsHandlerPartTimeUsersGetCountByStationGroupsGetApiResponse,
        GetCountByStationGroupsHandlerPartTimeUsersGetCountByStationGroupsGetApiArg
      >({
        query: (queryArg) => ({
          url: `/part-time-users/get-count-by-station-groups`,
          params: {
            ptRoleId: queryArg.ptRoleId,
            ptRoleIds: queryArg.ptRoleIds,
            stationId: queryArg.stationId,
          },
        }),
      }),
    getCountByStationsHandlerPartTimeUsersGetPtCountByStationsPost: build.query<
      GetCountByStationsHandlerPartTimeUsersGetPtCountByStationsPostApiResponse,
      GetCountByStationsHandlerPartTimeUsersGetPtCountByStationsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/part-time-users/get-pt-count-by-stations`,
        method: "POST",
        body: queryArg.getPtCountByStationsRequest,
        params: { ptRoleId: queryArg.ptRoleId, ptRoleIds: queryArg.ptRoleIds },
      }),
    }),
    getTotalCountByStationsHandlerPartTimeUsersGetTotalPtCountByStationsGet:
      build.query<
        GetTotalCountByStationsHandlerPartTimeUsersGetTotalPtCountByStationsGetApiResponse,
        GetTotalCountByStationsHandlerPartTimeUsersGetTotalPtCountByStationsGetApiArg
      >({
        query: (queryArg) => ({
          url: `/part-time-users/get-total-pt-count-by-stations`,
          params: {
            stationIds: queryArg.stationIds,
            isFilterNotConsent: queryArg.isFilterNotConsent,
          },
        }),
      }),
    listByStationsHandlerPartTimeUsersListByStationsGet: build.query<
      ListByStationsHandlerPartTimeUsersListByStationsGetApiResponse,
      ListByStationsHandlerPartTimeUsersListByStationsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/part-time-users/list-by-stations`,
        params: {
          stationIds: queryArg.stationIds,
          ptRoleId: queryArg.ptRoleId,
          showAll: queryArg.showAll,
          pageIndex: queryArg.pageIndex,
          pageSize: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    batchSendWelcomeMessageHandlerPartTimeUsersBatchSendWelcomeMessagePost:
      build.mutation<
        BatchSendWelcomeMessageHandlerPartTimeUsersBatchSendWelcomeMessagePostApiResponse,
        BatchSendWelcomeMessageHandlerPartTimeUsersBatchSendWelcomeMessagePostApiArg
      >({
        query: (queryArg) => ({
          url: `/part-time-users/batch-send-welcome-message`,
          method: "POST",
          body: queryArg.batchSendWelcomeMessageRequest,
        }),
      }),
    getPtUsersHandlerPartTimeUsersListPost: build.query<
      GetPtUsersHandlerPartTimeUsersListPostApiResponse,
      GetPtUsersHandlerPartTimeUsersListPostApiArg
    >({
      query: (queryArg) => ({
        url: `/part-time-users/list`,
        method: "POST",
        body: queryArg.getPtUsersRequest,
        params: {
          searchString: queryArg.searchString,
          pageIndex: queryArg.pageIndex,
          pageSize: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    reactivatePtUserHandlerPartTimeUsersPtUserIdReactivatePost: build.mutation<
      ReactivatePtUserHandlerPartTimeUsersPtUserIdReactivatePostApiResponse,
      ReactivatePtUserHandlerPartTimeUsersPtUserIdReactivatePostApiArg
    >({
      query: (queryArg) => ({
        url: `/part-time-users/${queryArg.ptUserId}/reactivate`,
        method: "POST",
      }),
    }),
    welcomePtUserHandlerPartTimeUsersPtUserIdWelcomePost: build.mutation<
      WelcomePtUserHandlerPartTimeUsersPtUserIdWelcomePostApiResponse,
      WelcomePtUserHandlerPartTimeUsersPtUserIdWelcomePostApiArg
    >({
      query: (queryArg) => ({
        url: `/part-time-users/${queryArg.ptUserId}/welcome`,
        method: "POST",
      }),
    }),
    getPtUserShiftsHandlerPartTimeUsersPtUserIdShiftsGet: build.query<
      GetPtUserShiftsHandlerPartTimeUsersPtUserIdShiftsGetApiResponse,
      GetPtUserShiftsHandlerPartTimeUsersPtUserIdShiftsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/part-time-users/${queryArg.ptUserId}/shifts`,
        params: {
          startBefore: queryArg.startBefore,
          endAfter: queryArg.endAfter,
        },
      }),
    }),
    schedulePtUserSuspensionHandlerPartTimeUsersPtUserIdScheduleSuspensionPost:
      build.mutation<
        SchedulePtUserSuspensionHandlerPartTimeUsersPtUserIdScheduleSuspensionPostApiResponse,
        SchedulePtUserSuspensionHandlerPartTimeUsersPtUserIdScheduleSuspensionPostApiArg
      >({
        query: (queryArg) => ({
          url: `/part-time-users/${queryArg.ptUserId}/schedule_suspension`,
          method: "POST",
          body: queryArg.schedulePtUserSuspensionRequest,
        }),
      }),
    listPtUserShiftCancelByShiftHandlerPtUserShiftCancelsShiftIdGet:
      build.query<
        ListPtUserShiftCancelByShiftHandlerPtUserShiftCancelsShiftIdGetApiResponse,
        ListPtUserShiftCancelByShiftHandlerPtUserShiftCancelsShiftIdGetApiArg
      >({
        query: (queryArg) => ({
          url: `/pt-user-shift-cancels/${queryArg.shiftId}`,
          params: {
            pageIndex: queryArg.pageIndex,
            pageSize: queryArg.pageSize,
          },
        }),
      }),
    listShiftCompleteReasonsHandlerShiftCompleteReasonsGet: build.query<
      ListShiftCompleteReasonsHandlerShiftCompleteReasonsGetApiResponse,
      ListShiftCompleteReasonsHandlerShiftCompleteReasonsGetApiArg
    >({
      query: () => ({ url: `/shift-complete-reasons` }),
    }),
    exportAllShiftsHandlerShiftsExportAllPost: build.mutation<
      ExportAllShiftsHandlerShiftsExportAllPostApiResponse,
      ExportAllShiftsHandlerShiftsExportAllPostApiArg
    >({
      query: () => ({ url: `/shifts/export-all`, method: "POST" }),
    }),
    listShiftCancelReasonsHandlerShiftsCancelReasonsGet: build.query<
      ListShiftCancelReasonsHandlerShiftsCancelReasonsGetApiResponse,
      ListShiftCancelReasonsHandlerShiftsCancelReasonsGetApiArg
    >({
      query: () => ({ url: `/shifts/cancel-reasons` }),
    }),
    listBorrowingRequestsHandlerShiftsBorrowingShiftRequestsGet: build.query<
      ListBorrowingRequestsHandlerShiftsBorrowingShiftRequestsGetApiResponse,
      ListBorrowingRequestsHandlerShiftsBorrowingShiftRequestsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/shifts/borrowing-shift-requests`,
        params: {
          stationId: queryArg.stationId,
          borrowing_status: queryArg.borrowingStatus,
          pageIndex: queryArg.pageIndex,
          pageSize: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    hireHandlerShiftsShiftIdHirePost: build.mutation<
      HireHandlerShiftsShiftIdHirePostApiResponse,
      HireHandlerShiftsShiftIdHirePostApiArg
    >({
      query: (queryArg) => ({
        url: `/shifts/${queryArg.shiftId}/hire`,
        method: "POST",
        body: queryArg.hireRequestParam,
      }),
    }),
    cancelHireHandlerShiftsShiftIdCancelHirePtUserPtUserIdPost: build.mutation<
      CancelHireHandlerShiftsShiftIdCancelHirePtUserPtUserIdPostApiResponse,
      CancelHireHandlerShiftsShiftIdCancelHirePtUserPtUserIdPostApiArg
    >({
      query: (queryArg) => ({
        url: `/shifts/${queryArg.shiftId}/cancel-hire-pt-user/${queryArg.ptUserId}`,
        method: "POST",
      }),
    }),
    contactHandlerShiftsShiftIdContactedPtUserPtUserIdPost: build.mutation<
      ContactHandlerShiftsShiftIdContactedPtUserPtUserIdPostApiResponse,
      ContactHandlerShiftsShiftIdContactedPtUserPtUserIdPostApiArg
    >({
      query: (queryArg) => ({
        url: `/shifts/${queryArg.shiftId}/contacted-pt-user/${queryArg.ptUserId}`,
        method: "POST",
        params: { isPartnerContact: queryArg.isPartnerContact },
      }),
    }),
    getChatHandlerShiftsShiftIdChatWithPtuserPtUserIdGet: build.query<
      GetChatHandlerShiftsShiftIdChatWithPtuserPtUserIdGetApiResponse,
      GetChatHandlerShiftsShiftIdChatWithPtuserPtUserIdGetApiArg
    >({
      query: (queryArg) => ({
        url: `/shifts/${queryArg.shiftId}/chat-with-ptuser/${queryArg.ptUserId}`,
      }),
    }),
    createShiftHandlerShiftsPost: build.mutation<
      CreateShiftHandlerShiftsPostApiResponse,
      CreateShiftHandlerShiftsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/shifts`,
        method: "POST",
        body: queryArg.createShiftRequest,
      }),
    }),
    listShiftsHandlerShiftsGet: build.query<
      ListShiftsHandlerShiftsGetApiResponse,
      ListShiftsHandlerShiftsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/shifts`,
        params: {
          workingStationId: queryArg.workingStationId,
          completionFilter: queryArg.completionFilter,
          year: queryArg.year,
          month: queryArg.month,
          before: queryArg.before,
          after: queryArg.after,
          status: queryArg.status,
          shift_type: queryArg.shiftType,
          search_string: queryArg.searchString,
          ptUserRoleIds: queryArg.ptUserRoleIds,
          rateId: queryArg.rateId,
          pageIndex: queryArg.pageIndex,
          pageSize: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    batchCancelShiftsHandlerShiftsBatchCancelPost: build.mutation<
      BatchCancelShiftsHandlerShiftsBatchCancelPostApiResponse,
      BatchCancelShiftsHandlerShiftsBatchCancelPostApiArg
    >({
      query: (queryArg) => ({
        url: `/shifts/batch-cancel`,
        method: "POST",
        body: queryArg.batchCancelShiftRequest,
      }),
    }),
    sendShiftRequestsHandlerShiftsShiftIdRequestsPost: build.mutation<
      SendShiftRequestsHandlerShiftsShiftIdRequestsPostApiResponse,
      SendShiftRequestsHandlerShiftsShiftIdRequestsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/shifts/${queryArg.shiftId}/requests`,
        method: "POST",
        body: queryArg.sendShiftRequestParam,
      }),
    }),
    listShiftRequestsWithNumberingHandlerShiftsShiftIdRequestsGet: build.query<
      ListShiftRequestsWithNumberingHandlerShiftsShiftIdRequestsGetApiResponse,
      ListShiftRequestsWithNumberingHandlerShiftsShiftIdRequestsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/shifts/${queryArg.shiftId}/requests`,
        params: {
          status_list: queryArg.statusList,
          include_station_ids: queryArg.includeStationIds,
          exclude_station_ids: queryArg.excludeStationIds,
          pt_user_418_status: queryArg.ptUser418Status,
          should_show_deleted: queryArg.shouldShowDeleted,
          pageIndex: queryArg.pageIndex,
          pageSize: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    getAvailablePtUserHandlerShiftsAvailablePtUsersGet: build.query<
      GetAvailablePtUserHandlerShiftsAvailablePtUsersGetApiResponse,
      GetAvailablePtUserHandlerShiftsAvailablePtUsersGetApiArg
    >({
      query: (queryArg) => ({
        url: `/shifts/available-pt-users`,
        params: {
          filterType: queryArg.filterType,
          showAll: queryArg.showAll,
          status418: queryArg.status418,
          shiftId: queryArg.shiftId,
        },
      }),
    }),
    getAvailablePtCountHandlerShiftsAvailablePtCountGet: build.query<
      GetAvailablePtCountHandlerShiftsAvailablePtCountGetApiResponse,
      GetAvailablePtCountHandlerShiftsAvailablePtCountGetApiArg
    >({
      query: (queryArg) => ({
        url: `/shifts/available-pt-count`,
        params: { status418: queryArg.status418, shiftId: queryArg.shiftId },
      }),
    }),
    getAvailablePtUserByStationsHandlerShiftsAvailablePtUsersByStationsGet:
      build.query<
        GetAvailablePtUserByStationsHandlerShiftsAvailablePtUsersByStationsGetApiResponse,
        GetAvailablePtUserByStationsHandlerShiftsAvailablePtUsersByStationsGetApiArg
      >({
        query: (queryArg) => ({
          url: `/shifts/available-pt-users-by-stations`,
          params: {
            stationIds: queryArg.stationIds,
            showAll: queryArg.showAll,
            status418: queryArg.status418,
            shiftId: queryArg.shiftId,
          },
        }),
      }),
    getAvailablePtCountByStationsGroupsHandlerShiftsAvailablePtCountByStationsPost:
      build.query<
        GetAvailablePtCountByStationsGroupsHandlerShiftsAvailablePtCountByStationsPostApiResponse,
        GetAvailablePtCountByStationsGroupsHandlerShiftsAvailablePtCountByStationsPostApiArg
      >({
        query: (queryArg) => ({
          url: `/shifts/available-pt-count-by-stations`,
          method: "POST",
          body: queryArg.getAvailablePtCountByStationsGroupsRequest,
          params: { status418: queryArg.status418, shiftId: queryArg.shiftId },
        }),
      }),
    getAvailablePtCountByStationsHandlerShiftsAvailablePtCountByStationsGet:
      build.query<
        GetAvailablePtCountByStationsHandlerShiftsAvailablePtCountByStationsGetApiResponse,
        GetAvailablePtCountByStationsHandlerShiftsAvailablePtCountByStationsGetApiArg
      >({
        query: (queryArg) => ({
          url: `/shifts/available-pt-count-by-stations`,
          params: {
            stationIds: queryArg.stationIds,
            status418: queryArg.status418,
            shiftId: queryArg.shiftId,
          },
        }),
      }),
    getShiftByIdHandlerShiftsShiftIdGet: build.query<
      GetShiftByIdHandlerShiftsShiftIdGetApiResponse,
      GetShiftByIdHandlerShiftsShiftIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/shifts/${queryArg.shiftId}` }),
    }),
    editShiftHandlerShiftsShiftIdPost: build.mutation<
      EditShiftHandlerShiftsShiftIdPostApiResponse,
      EditShiftHandlerShiftsShiftIdPostApiArg
    >({
      query: (queryArg) => ({
        url: `/shifts/${queryArg.shiftId}`,
        method: "POST",
        body: queryArg.editShiftRequest,
      }),
    }),
    makeExpireShiftHandlerShiftsShiftIdMakeExpirePost: build.mutation<
      MakeExpireShiftHandlerShiftsShiftIdMakeExpirePostApiResponse,
      MakeExpireShiftHandlerShiftsShiftIdMakeExpirePostApiArg
    >({
      query: (queryArg) => ({
        url: `/shifts/${queryArg.shiftId}/make-expire`,
        method: "POST",
      }),
    }),
    borrowingRequestHandlerShiftsRequestsShiftIdPtUserIdBorrowingPost:
      build.mutation<
        BorrowingRequestHandlerShiftsRequestsShiftIdPtUserIdBorrowingPostApiResponse,
        BorrowingRequestHandlerShiftsRequestsShiftIdPtUserIdBorrowingPostApiArg
      >({
        query: (queryArg) => ({
          url: `/shifts/requests/${queryArg.shiftId}/${queryArg.ptUserId}/borrowing`,
          method: "POST",
          body: queryArg.borrowingRequestParam,
        }),
      }),
    listStationHandlerStationsGet: build.query<
      ListStationHandlerStationsGetApiResponse,
      ListStationHandlerStationsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/stations`,
        params: {
          q: queryArg.q,
          pageIndex: queryArg.pageIndex,
          pageSize: queryArg.pageSize,
        },
      }),
    }),
    listByShiftHandlerStationsListByShiftShiftIdGet: build.query<
      ListByShiftHandlerStationsListByShiftShiftIdGetApiResponse,
      ListByShiftHandlerStationsListByShiftShiftIdGetApiArg
    >({
      query: (queryArg) => ({
        url: `/stations/list-by-shift/${queryArg.shiftId}`,
        params: { filterType: queryArg.filterType },
      }),
    }),
    getWorkingStationHandlerWorkingStationsIdGet: build.query<
      GetWorkingStationHandlerWorkingStationsIdGetApiResponse,
      GetWorkingStationHandlerWorkingStationsIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/working-stations/${queryArg.id}` }),
    }),
    listWorkingStationHandlerWorkingStationsGet: build.query<
      ListWorkingStationHandlerWorkingStationsGetApiResponse,
      ListWorkingStationHandlerWorkingStationsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/working-stations`,
        params: {
          q: queryArg.q,
          pageIndex: queryArg.pageIndex,
          pageSize: queryArg.pageSize,
        },
      }),
    }),
    listTemplateMessageHandlerTemplateMessageGet: build.query<
      ListTemplateMessageHandlerTemplateMessageGetApiResponse,
      ListTemplateMessageHandlerTemplateMessageGetApiArg
    >({
      query: () => ({ url: `/template-message` }),
    }),
    testSendHandlerTemplateMessageTestSendPost: build.mutation<
      TestSendHandlerTemplateMessageTestSendPostApiResponse,
      TestSendHandlerTemplateMessageTestSendPostApiArg
    >({
      query: (queryArg) => ({
        url: `/template-message/test-send`,
        method: "POST",
        body: queryArg.testRequest,
      }),
    }),
    listIncidentContentTemplateHandlerIncidentContentTemplatesGet: build.query<
      ListIncidentContentTemplateHandlerIncidentContentTemplatesGetApiResponse,
      ListIncidentContentTemplateHandlerIncidentContentTemplatesGetApiArg
    >({
      query: () => ({ url: `/incident-content-templates` }),
    }),
    generateTwilioTokenHandlerTwilioTokenPost: build.mutation<
      GenerateTwilioTokenHandlerTwilioTokenPostApiResponse,
      GenerateTwilioTokenHandlerTwilioTokenPostApiArg
    >({
      query: () => ({ url: `/twilio/token`, method: "POST" }),
    }),
    messageReceivedEventHandlerWebhookTwilioMessagePost: build.mutation<
      MessageReceivedEventHandlerWebhookTwilioMessagePostApiResponse,
      MessageReceivedEventHandlerWebhookTwilioMessagePostApiArg
    >({
      query: (queryArg) => ({
        url: `/webhook/twilio/message`,
        method: "POST",
        body: queryArg.bodyMessageReceivedEventHandlerWebhookTwilioMessagePost,
        headers: { "X-Twilio-Signature": queryArg["X-Twilio-Signature"] },
      }),
    }),
    statusUpdateEventHandlerWebhookTwilioStatusPost: build.mutation<
      StatusUpdateEventHandlerWebhookTwilioStatusPostApiResponse,
      StatusUpdateEventHandlerWebhookTwilioStatusPostApiArg
    >({
      query: (queryArg) => ({
        url: `/webhook/twilio/status`,
        method: "POST",
        body: queryArg.bodyStatusUpdateEventHandlerWebhookTwilioStatusPost,
        headers: { "X-Twilio-Signature": queryArg["X-Twilio-Signature"] },
      }),
    }),
    listNotificationsHandlerNotificationsGet: build.query<
      ListNotificationsHandlerNotificationsGetApiResponse,
      ListNotificationsHandlerNotificationsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/notifications`,
        params: {
          stationId: queryArg.stationId,
          pageIndex: queryArg.pageIndex,
          pageSize: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    readNotificationHandlerNotificationsNotificationIdReadPost: build.mutation<
      ReadNotificationHandlerNotificationsNotificationIdReadPostApiResponse,
      ReadNotificationHandlerNotificationsNotificationIdReadPostApiArg
    >({
      query: (queryArg) => ({
        url: `/notifications/${queryArg.notificationId}/read`,
        method: "POST",
      }),
    }),
    createAssetHandlerAssetsPost: build.mutation<
      CreateAssetHandlerAssetsPostApiResponse,
      CreateAssetHandlerAssetsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/assets`,
        method: "POST",
        body: queryArg.createAssetRequest,
      }),
    }),
    generateTokenHandlerTokenGet: build.query<
      GenerateTokenHandlerTokenGetApiResponse,
      GenerateTokenHandlerTokenGetApiArg
    >({
      query: (queryArg) => ({
        url: `/token`,
        params: { msalAccessToken: queryArg.msalAccessToken },
      }),
    }),
    generateWebsocketTokenHandlerTokenWebsocketGet: build.query<
      GenerateWebsocketTokenHandlerTokenWebsocketGetApiResponse,
      GenerateWebsocketTokenHandlerTokenWebsocketGetApiArg
    >({
      query: () => ({ url: `/token/websocket` }),
    }),
    validateInviteHandlerUsersValidateInviteGet: build.query<
      ValidateInviteHandlerUsersValidateInviteGetApiResponse,
      ValidateInviteHandlerUsersValidateInviteGetApiArg
    >({
      query: (queryArg) => ({
        url: `/users/validate-invite`,
        params: { email: queryArg.email },
      }),
    }),
    inviteManagerToStationHandlerUsersInviteUserIdPost: build.mutation<
      InviteManagerToStationHandlerUsersInviteUserIdPostApiResponse,
      InviteManagerToStationHandlerUsersInviteUserIdPostApiArg
    >({
      query: (queryArg) => ({
        url: `/users/invite/${queryArg.userId}`,
        method: "POST",
        body: queryArg.inviteUserRequest,
      }),
    }),
    leaveStationHandlerUsersLeaveStationPost: build.mutation<
      LeaveStationHandlerUsersLeaveStationPostApiResponse,
      LeaveStationHandlerUsersLeaveStationPostApiArg
    >({
      query: (queryArg) => ({
        url: `/users/leave-station`,
        method: "POST",
        body: queryArg.leaveStationRequest,
      }),
    }),
    createUserHandlerUsersPost: build.mutation<
      CreateUserHandlerUsersPostApiResponse,
      CreateUserHandlerUsersPostApiArg
    >({
      query: (queryArg) => ({
        url: `/users`,
        method: "POST",
        body: queryArg.createUserRequest,
      }),
    }),
    listUserHandlerUsersGet: build.query<
      ListUserHandlerUsersGetApiResponse,
      ListUserHandlerUsersGetApiArg
    >({
      query: (queryArg) => ({
        url: `/users`,
        params: {
          role: queryArg.role,
          stationId: queryArg.stationId,
          railwayLineId: queryArg.railwayLineId,
          pageIndex: queryArg.pageIndex,
          pageSize: queryArg.pageSize,
        },
      }),
    }),
    editUserHandlerUsersUserIdPost: build.mutation<
      EditUserHandlerUsersUserIdPostApiResponse,
      EditUserHandlerUsersUserIdPostApiArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}`,
        method: "POST",
        body: queryArg.editUserRequest,
      }),
    }),
    getUserHandlerUsersUserIdGet: build.query<
      GetUserHandlerUsersUserIdGetApiResponse,
      GetUserHandlerUsersUserIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/users/${queryArg.userId}` }),
    }),
    listUserStationInviteRecordHandlerUserStationInviteRecordsGet: build.query<
      ListUserStationInviteRecordHandlerUserStationInviteRecordsGetApiResponse,
      ListUserStationInviteRecordHandlerUserStationInviteRecordsGetApiArg
    >({
      query: () => ({ url: `/user-station-invite-records` }),
    }),
    readUserStationInviteRecordHandlerUserStationInviteRecordsReadPost:
      build.mutation<
        ReadUserStationInviteRecordHandlerUserStationInviteRecordsReadPostApiResponse,
        ReadUserStationInviteRecordHandlerUserStationInviteRecordsReadPostApiArg
      >({
        query: () => ({
          url: `/user-station-invite-records/read`,
          method: "POST",
        }),
      }),
    sendMasterShiftInvitationHandlerMasterShiftsMasterShiftIdInvitePost:
      build.mutation<
        SendMasterShiftInvitationHandlerMasterShiftsMasterShiftIdInvitePostApiResponse,
        SendMasterShiftInvitationHandlerMasterShiftsMasterShiftIdInvitePostApiArg
      >({
        query: (queryArg) => ({
          url: `/master-shifts/${queryArg.masterShiftId}/invite`,
          method: "POST",
          body: queryArg.sendMasterShiftInvitationRequest,
        }),
      }),
    getMasterShiftRequestCountHandlerMasterShiftsMasterShiftIdRequestCountGet:
      build.query<
        GetMasterShiftRequestCountHandlerMasterShiftsMasterShiftIdRequestCountGetApiResponse,
        GetMasterShiftRequestCountHandlerMasterShiftsMasterShiftIdRequestCountGetApiArg
      >({
        query: (queryArg) => ({
          url: `/master-shifts/${queryArg.masterShiftId}/request-count`,
        }),
      }),
    getCountByStationGroupsHandlerMasterShiftsGetCountByStationGroupsGet:
      build.query<
        GetCountByStationGroupsHandlerMasterShiftsGetCountByStationGroupsGetApiResponse,
        GetCountByStationGroupsHandlerMasterShiftsGetCountByStationGroupsGetApiArg
      >({
        query: (queryArg) => ({
          url: `/master-shifts/get-count-by-station-groups`,
          params: {
            ptRoleId: queryArg.ptRoleId,
            ptRoleIds: queryArg.ptRoleIds,
            masterShiftId: queryArg.masterShiftId,
            stationId: queryArg.stationId,
          },
        }),
      }),
    getAvailablePtUserByStationsHandlerMasterShiftsAvailablePtUsersByStationsGet:
      build.query<
        GetAvailablePtUserByStationsHandlerMasterShiftsAvailablePtUsersByStationsGetApiResponse,
        GetAvailablePtUserByStationsHandlerMasterShiftsAvailablePtUsersByStationsGetApiArg
      >({
        query: (queryArg) => ({
          url: `/master-shifts/available-pt-users-by-stations`,
          params: {
            masterShiftId: queryArg.masterShiftId,
            stationIds: queryArg.stationIds,
            ptRoleId: queryArg.ptRoleId,
            ptRoleIds: queryArg.ptRoleIds,
            showAll: queryArg.showAll,
            pageIndex: queryArg.pageIndex,
            pageSize: queryArg.pageSize,
            sort: queryArg.sort,
          },
        }),
      }),
    getMasterShiftHandlerMasterShiftsMasterShiftIdGet: build.query<
      GetMasterShiftHandlerMasterShiftsMasterShiftIdGetApiResponse,
      GetMasterShiftHandlerMasterShiftsMasterShiftIdGetApiArg
    >({
      query: (queryArg) => ({
        url: `/master-shifts/${queryArg.masterShiftId}`,
      }),
    }),
    createMasterShiftHandlerMasterShiftsPost: build.mutation<
      CreateMasterShiftHandlerMasterShiftsPostApiResponse,
      CreateMasterShiftHandlerMasterShiftsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/master-shifts`,
        method: "POST",
        body: queryArg.createMasterShiftRequest,
      }),
    }),
    listStationTeamHandlerStationTeamsGet: build.query<
      ListStationTeamHandlerStationTeamsGetApiResponse,
      ListStationTeamHandlerStationTeamsGetApiArg
    >({
      query: () => ({ url: `/station-teams` }),
    }),
    listRailwayLineHandlerRailwayLinesGet: build.query<
      ListRailwayLineHandlerRailwayLinesGetApiResponse,
      ListRailwayLineHandlerRailwayLinesGetApiArg
    >({
      query: () => ({ url: `/railway-lines` }),
    }),
    listPublicHolidaysHandlerPublicHolidaysGet: build.query<
      ListPublicHolidaysHandlerPublicHolidaysGetApiResponse,
      ListPublicHolidaysHandlerPublicHolidaysGetApiArg
    >({
      query: (queryArg) => ({
        url: `/public-holidays`,
        params: { startDate: queryArg.startDate, endDate: queryArg.endDate },
      }),
    }),
    listShiftRateHandlerShiftRatesGet: build.query<
      ListShiftRateHandlerShiftRatesGetApiResponse,
      ListShiftRateHandlerShiftRatesGetApiArg
    >({
      query: () => ({ url: `/shift-rates` }),
    }),
    listShiftRequestHandlerShiftRequestsGet: build.query<
      ListShiftRequestHandlerShiftRequestsGetApiResponse,
      ListShiftRequestHandlerShiftRequestsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/shift-requests`,
        params: {
          isOut: queryArg.isOut,
          shiftWorkingStationId: queryArg.shiftWorkingStationId,
          shiftBefore: queryArg.shiftBefore,
          shiftAfter: queryArg.shiftAfter,
          shiftStatus: queryArg.shiftStatus,
          shiftSearchString: queryArg.shiftSearchString,
          shiftRateId: queryArg.shiftRateId,
          ptStationId: queryArg.ptStationId,
          ptSearchString: queryArg.ptSearchString,
          ptRoleId: queryArg.ptRoleId,
          shiftRequestStatus: queryArg.shiftRequestStatus,
          sort: queryArg.sort,
          pageIndex: queryArg.pageIndex,
          pageSize: queryArg.pageSize,
        },
      }),
    }),
    exportShiftRequestHandlerShiftRequestsExportPost: build.mutation<
      ExportShiftRequestHandlerShiftRequestsExportPostApiResponse,
      ExportShiftRequestHandlerShiftRequestsExportPostApiArg
    >({
      query: (queryArg) => ({
        url: `/shift-requests/export`,
        method: "POST",
        params: {
          isOut: queryArg.isOut,
          sort: queryArg.sort,
          shiftWorkingStationId: queryArg.shiftWorkingStationId,
          shiftBefore: queryArg.shiftBefore,
          shiftAfter: queryArg.shiftAfter,
          shiftStatus: queryArg.shiftStatus,
          shiftSearchString: queryArg.shiftSearchString,
          shiftRateId: queryArg.shiftRateId,
          ptStationId: queryArg.ptStationId,
          ptSearchString: queryArg.ptSearchString,
          ptRoleId: queryArg.ptRoleId,
          shiftRequestStatus: queryArg.shiftRequestStatus,
        },
      }),
    }),
    addShiftFullTimeAttendanceHandlerShiftFullTimeAttendancesPost:
      build.mutation<
        AddShiftFullTimeAttendanceHandlerShiftFullTimeAttendancesPostApiResponse,
        AddShiftFullTimeAttendanceHandlerShiftFullTimeAttendancesPostApiArg
      >({
        query: (queryArg) => ({
          url: `/shift-full-time-attendances`,
          method: "POST",
          body: queryArg.createShiftFullTimeAttendanceRequest,
          params: { shiftId: queryArg.shiftId },
        }),
      }),
    listShiftFullTimeAttendanceByShiftHandlerShiftFullTimeAttendancesGet:
      build.query<
        ListShiftFullTimeAttendanceByShiftHandlerShiftFullTimeAttendancesGetApiResponse,
        ListShiftFullTimeAttendanceByShiftHandlerShiftFullTimeAttendancesGetApiArg
      >({
        query: (queryArg) => ({
          url: `/shift-full-time-attendances`,
          params: { shiftId: queryArg.shiftId },
        }),
      }),
    removeShiftFullTimeAttendanceHandlerShiftFullTimeAttendancesAttendanceIdDelete:
      build.mutation<
        RemoveShiftFullTimeAttendanceHandlerShiftFullTimeAttendancesAttendanceIdDeleteApiResponse,
        RemoveShiftFullTimeAttendanceHandlerShiftFullTimeAttendancesAttendanceIdDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/shift-full-time-attendances/${queryArg.attendanceId}`,
          method: "DELETE",
        }),
      }),
    exportUsageHandlerWhatsappUsageExportPost: build.mutation<
      ExportUsageHandlerWhatsappUsageExportPostApiResponse,
      ExportUsageHandlerWhatsappUsageExportPostApiArg
    >({
      query: (queryArg) => ({
        url: `/whatsapp-usage/export`,
        method: "POST",
        body: queryArg.generateUsageRequest,
      }),
    }),
    applyShiftHandlerPtShiftsShiftIdApplyPost: build.mutation<
      ApplyShiftHandlerPtShiftsShiftIdApplyPostApiResponse,
      ApplyShiftHandlerPtShiftsShiftIdApplyPostApiArg
    >({
      query: (queryArg) => ({
        url: `/pt/shifts/${queryArg.shiftId}/apply`,
        method: "POST",
      }),
    }),
    ptUserListShiftsRateByDateHandlerPtShiftsListShiftRateByDateGet:
      build.query<
        PtUserListShiftsRateByDateHandlerPtShiftsListShiftRateByDateGetApiResponse,
        PtUserListShiftsRateByDateHandlerPtShiftsListShiftRateByDateGetApiArg
      >({
        query: (queryArg) => ({
          url: `/pt/shifts/list-shift-rate-by-date`,
          params: {
            startBefore: queryArg.startBefore,
            endAfter: queryArg.endAfter,
            shiftRequestStatuses: queryArg.shiftRequestStatuses,
            borrowingShiftStatuses: queryArg.borrowingShiftStatuses,
            workingStationId: queryArg.workingStationId,
            showNoneStationIds: queryArg.showNoneStationIds,
            shiftStatus: queryArg.shiftStatus,
            excludeNonBookmarkedShifts: queryArg.excludeNonBookmarkedShifts,
            masterShiftId: queryArg.masterShiftId,
            shiftType: queryArg.shiftType,
          },
        }),
      }),
    cancelApplyShiftHandlerPtShiftsShiftIdCancelApplyPost: build.mutation<
      CancelApplyShiftHandlerPtShiftsShiftIdCancelApplyPostApiResponse,
      CancelApplyShiftHandlerPtShiftsShiftIdCancelApplyPostApiArg
    >({
      query: (queryArg) => ({
        url: `/pt/shifts/${queryArg.shiftId}/cancel-apply`,
        method: "POST",
      }),
    }),
    getShiftApplicationHandlerPtShiftsApplicationGet: build.query<
      GetShiftApplicationHandlerPtShiftsApplicationGetApiResponse,
      GetShiftApplicationHandlerPtShiftsApplicationGetApiArg
    >({
      query: (queryArg) => ({
        url: `/pt/shifts/application`,
        params: { token: queryArg.token },
      }),
    }),
    getUnreadShiftCountHandlerPtShiftsPtUnreadShiftCountGet: build.query<
      GetUnreadShiftCountHandlerPtShiftsPtUnreadShiftCountGetApiResponse,
      GetUnreadShiftCountHandlerPtShiftsPtUnreadShiftCountGetApiArg
    >({
      query: () => ({ url: `/pt/shifts/pt-unread-shift-count` }),
    }),
    ptUserListShiftsHandlerPtShiftsListGet: build.query<
      PtUserListShiftsHandlerPtShiftsListGetApiResponse,
      PtUserListShiftsHandlerPtShiftsListGetApiArg
    >({
      query: (queryArg) => ({
        url: `/pt/shifts/list`,
        params: {
          startBefore: queryArg.startBefore,
          endAfter: queryArg.endAfter,
          shiftRequestStatuses: queryArg.shiftRequestStatuses,
          borrowingShiftStatuses: queryArg.borrowingShiftStatuses,
          workingStationId: queryArg.workingStationId,
          showNoneStationIds: queryArg.showNoneStationIds,
          shiftStatus: queryArg.shiftStatus,
          excludeNonBookmarkedShifts: queryArg.excludeNonBookmarkedShifts,
          masterShiftId: queryArg.masterShiftId,
          shiftType: queryArg.shiftType,
          pageIndex: queryArg.pageIndex,
          pageSize: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    markShiftReadHandlerPtShiftsShiftIdMarkReadPost: build.mutation<
      MarkShiftReadHandlerPtShiftsShiftIdMarkReadPostApiResponse,
      MarkShiftReadHandlerPtShiftsShiftIdMarkReadPostApiArg
    >({
      query: (queryArg) => ({
        url: `/pt/shifts/${queryArg.shiftId}/mark-read`,
        method: "POST",
      }),
    }),
    getShiftByIdHandlerPtShiftsShiftIdGet: build.query<
      GetShiftByIdHandlerPtShiftsShiftIdGetApiResponse,
      GetShiftByIdHandlerPtShiftsShiftIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/pt/shifts/${queryArg.shiftId}` }),
    }),
    likeShiftHandlerPtShiftsShiftIdLikePost: build.mutation<
      LikeShiftHandlerPtShiftsShiftIdLikePostApiResponse,
      LikeShiftHandlerPtShiftsShiftIdLikePostApiArg
    >({
      query: (queryArg) => ({
        url: `/pt/shifts/${queryArg.shiftId}/like`,
        method: "POST",
      }),
    }),
    unlikeShiftHandlerPtShiftsShiftIdUnlikePost: build.mutation<
      UnlikeShiftHandlerPtShiftsShiftIdUnlikePostApiResponse,
      UnlikeShiftHandlerPtShiftsShiftIdUnlikePostApiArg
    >({
      query: (queryArg) => ({
        url: `/pt/shifts/${queryArg.shiftId}/unlike`,
        method: "POST",
      }),
    }),
    bookmarkShiftHandlerPtShiftsShiftIdBookmarkPost: build.mutation<
      BookmarkShiftHandlerPtShiftsShiftIdBookmarkPostApiResponse,
      BookmarkShiftHandlerPtShiftsShiftIdBookmarkPostApiArg
    >({
      query: (queryArg) => ({
        url: `/pt/shifts/${queryArg.shiftId}/bookmark`,
        method: "POST",
      }),
    }),
    unbookmarkShiftHandlerPtShiftsShiftIdUnbookmarkPost: build.mutation<
      UnbookmarkShiftHandlerPtShiftsShiftIdUnbookmarkPostApiResponse,
      UnbookmarkShiftHandlerPtShiftsShiftIdUnbookmarkPostApiArg
    >({
      query: (queryArg) => ({
        url: `/pt/shifts/${queryArg.shiftId}/unbookmark`,
        method: "POST",
      }),
    }),
    getShiftRequestByIdHandlerPtShiftRequestsShiftsShiftIdGet: build.query<
      GetShiftRequestByIdHandlerPtShiftRequestsShiftsShiftIdGetApiResponse,
      GetShiftRequestByIdHandlerPtShiftRequestsShiftsShiftIdGetApiArg
    >({
      query: (queryArg) => ({
        url: `/pt/shift-requests/shifts/${queryArg.shiftId}`,
      }),
    }),
    markShiftRequestReadHandlerPtShiftRequestsMarkReadPost: build.mutation<
      MarkShiftRequestReadHandlerPtShiftRequestsMarkReadPostApiResponse,
      MarkShiftRequestReadHandlerPtShiftRequestsMarkReadPostApiArg
    >({
      query: (queryArg) => ({
        url: `/pt/shift-requests/mark-read`,
        method: "POST",
        body: queryArg.markShiftRequestsReadRequest,
      }),
    }),
    ptUserAuthenticateHandlerPtPtUserAuthenticateGet: build.query<
      PtUserAuthenticateHandlerPtPtUserAuthenticateGetApiResponse,
      PtUserAuthenticateHandlerPtPtUserAuthenticateGetApiArg
    >({
      query: () => ({ url: `/pt/pt-user-authenticate` }),
    }),
    getMasterShiftIdFromApplicationHandlerPtMasterShiftsIdFromApplicationGet:
      build.query<
        GetMasterShiftIdFromApplicationHandlerPtMasterShiftsIdFromApplicationGetApiResponse,
        GetMasterShiftIdFromApplicationHandlerPtMasterShiftsIdFromApplicationGetApiArg
      >({
        query: (queryArg) => ({
          url: `/pt/master-shifts/id-from-application`,
          params: { token: queryArg.token },
        }),
      }),
    applyMasterShiftByIdHandlerPtMasterShiftsJobIdApplyPost: build.mutation<
      ApplyMasterShiftByIdHandlerPtMasterShiftsJobIdApplyPostApiResponse,
      ApplyMasterShiftByIdHandlerPtMasterShiftsJobIdApplyPostApiArg
    >({
      query: (queryArg) => ({
        url: `/pt/master-shifts/${queryArg.jobId}/apply`,
        method: "POST",
        body: queryArg.applyMasterShiftRequest,
      }),
    }),
    getMasterShiftDetailHandlerPtMasterShiftsJobIdGet: build.query<
      GetMasterShiftDetailHandlerPtMasterShiftsJobIdGetApiResponse,
      GetMasterShiftDetailHandlerPtMasterShiftsJobIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/pt/master-shifts/${queryArg.jobId}` }),
    }),
    consentPtUserHandlerPtPartTimeUsersPtUserIdConsentPost: build.mutation<
      ConsentPtUserHandlerPtPartTimeUsersPtUserIdConsentPostApiResponse,
      ConsentPtUserHandlerPtPartTimeUsersPtUserIdConsentPostApiArg
    >({
      query: (queryArg) => ({
        url: `/pt/part-time-users/${queryArg.ptUserId}/consent`,
        method: "POST",
      }),
    }),
    listRailwayLinesHandlerPtRailwayLinesGet: build.query<
      ListRailwayLinesHandlerPtRailwayLinesGetApiResponse,
      ListRailwayLinesHandlerPtRailwayLinesGetApiArg
    >({
      query: () => ({ url: `/pt/railway-lines` }),
    }),
    listFrontlineAppConfigHandlerPtFrontlineAppConfigGet: build.query<
      ListFrontlineAppConfigHandlerPtFrontlineAppConfigGetApiResponse,
      ListFrontlineAppConfigHandlerPtFrontlineAppConfigGetApiArg
    >({
      query: () => ({ url: `/pt/frontline-app-config` }),
    }),
    getPtUserShiftCancelHandlerPtPtUserShiftCancelShiftIdGet: build.query<
      GetPtUserShiftCancelHandlerPtPtUserShiftCancelShiftIdGetApiResponse,
      GetPtUserShiftCancelHandlerPtPtUserShiftCancelShiftIdGetApiArg
    >({
      query: (queryArg) => ({
        url: `/pt/pt-user-shift-cancel/${queryArg.shiftId}`,
      }),
    }),
    listCustomerRailwayLinesHandlerPtCustomerRailwayLinesGet: build.query<
      ListCustomerRailwayLinesHandlerPtCustomerRailwayLinesGetApiResponse,
      ListCustomerRailwayLinesHandlerPtCustomerRailwayLinesGetApiArg
    >({
      query: () => ({ url: `/pt/customer-railway-lines` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type ListAdminAppConfigHandlerAdminAppConfigGetApiResponse =
  /** status 200 Successful Response */ AdminAppConfig;
export type ListAdminAppConfigHandlerAdminAppConfigGetApiArg = void;
export type CreateAnnouncementHandlerAnnouncementsPostApiResponse =
  /** status 200 Successful Response */ CreateAnnouncementResponse;
export type CreateAnnouncementHandlerAnnouncementsPostApiArg = {
  createAnnouncementRequest: CreateAnnouncementRequest;
};
export type ListAnnouncementHandlerAnnouncementsListGetApiResponse =
  /** status 200 Successful Response */ ListAnnouncementResponse;
export type ListAnnouncementHandlerAnnouncementsListGetApiArg = {
  pageIndex?: number;
  pageSize?: number;
  sort?: string | null;
};
export type GetAnnouncementByIdHandlerAnnouncementsAnnouncementIdGetApiResponse =
  /** status 200 Successful Response */ GetAnnouncementByIdResponse;
export type GetAnnouncementByIdHandlerAnnouncementsAnnouncementIdGetApiArg = {
  announcementId: number;
};
export type AuthenticateHandlerAuthenticateGetApiResponse =
  /** status 200 Successful Response */ MeUser;
export type AuthenticateHandlerAuthenticateGetApiArg = {
  msalAccessToken: string;
};
export type ListMessageHandlerChatsChatIdMessagesGetApiResponse =
  /** status 200 Successful Response */ ListChatMessageResponse;
export type ListMessageHandlerChatsChatIdMessagesGetApiArg = {
  chatId: number;
  direction: "before" | "after";
  before?: string | null;
  after?: string | null;
  limit?: number;
};
export type GetUnreadChatCountHandlerChatsUnreadChatCountGetApiResponse =
  /** status 200 Successful Response */ UnreadChatCountResponse;
export type GetUnreadChatCountHandlerChatsUnreadChatCountGetApiArg = void;
export type GetChatHandlerChatsChatIdGetApiResponse =
  /** status 200 Successful Response */ GetChatResponse;
export type GetChatHandlerChatsChatIdGetApiArg = {
  chatId: number;
};
export type ListChatHandlerChatsGetApiResponse =
  /** status 200 Successful Response */ ListChatResponse;
export type ListChatHandlerChatsGetApiArg = {
  q?: string | null;
  stationId?: number | null;
  excludeSuspendedPtUsers?: boolean | null;
  before?: string | null;
  limit?: number;
};
export type MarkChatReadHandlerChatsChatIdMarkReadPostApiResponse =
  /** status 200 Successful Response */ any;
export type MarkChatReadHandlerChatsChatIdMarkReadPostApiArg = {
  chatId: number;
};
export type SendTemplateMessageHandlerChatsChatIdTemplateMessagePostApiResponse =
  /** status 200 Successful Response */ SendTemplateMessageResponse;
export type SendTemplateMessageHandlerChatsChatIdTemplateMessagePostApiArg = {
  chatId: number;
  sendTemplateMessageRequest: SendTemplateMessageRequest;
};
export type SendTextMessageHandlerChatsChatIdTextMessagePostApiResponse =
  /** status 200 Successful Response */ SendTextMessageResponse;
export type SendTextMessageHandlerChatsChatIdTextMessagePostApiArg = {
  chatId: number;
  sendTextMessageRequest: SendTextMessageRequest;
};
export type SyncConversationParticipantHandlerCronSyncConversationParticipantPostApiResponse =
  /** status 200 Successful Response */ any;
export type SyncConversationParticipantHandlerCronSyncConversationParticipantPostApiArg =
  {
    "x-mtr-oneclick-master-api-key": string;
  };
export type ApiHealthCheckHandlerApiHealthCheckGetApiResponse =
  /** status 200 Successful Response */ any;
export type ApiHealthCheckHandlerApiHealthCheckGetApiArg = void;
export type HealthCheckHandlerHealthCheckGetApiResponse =
  /** status 200 Successful Response */ any;
export type HealthCheckHandlerHealthCheckGetApiArg = void;
export type GetMeHandlerMeGetApiResponse =
  /** status 200 Successful Response */ MeUser;
export type GetMeHandlerMeGetApiArg = void;
export type UpdateLanguagePreferenceHandlerMeLanguagePreferencePostApiResponse =
  /** status 200 Successful Response */ MeUser;
export type UpdateLanguagePreferenceHandlerMeLanguagePreferencePostApiArg = {
  updateLanguagePreferenceRequest: UpdateLanguagePreferenceRequest;
};
export type SwitchProfileHandlerMeSwitchProfilePostApiResponse =
  /** status 200 Successful Response */ MeSwitchProfileResponse;
export type SwitchProfileHandlerMeSwitchProfilePostApiArg = {
  meSwitchProfileRequest: MeSwitchProfileRequest;
};
export type RevokeTokenHandlerMeRevokeTokenPostApiResponse =
  /** status 204 Successful Response */ undefined;
export type RevokeTokenHandlerMeRevokeTokenPostApiArg = void;
export type DeletePtUserWorkingStatusRecordHandlerPartTimeUsersPtUserIdWorkingStatusRecordDeleteApiResponse =
  /** status 200 Successful Response */ any;
export type DeletePtUserWorkingStatusRecordHandlerPartTimeUsersPtUserIdWorkingStatusRecordDeleteApiArg =
  {
    ptUserId: number;
    year: number;
    weekNumber: number;
  };
export type UpsertPtUserWorkingStatusRecordHandlerPartTimeUsersPtUserIdWorkingStatusRecordPostApiResponse =
  /** status 200 Successful Response */ UpsertPtUserWorkingStatusRecordResponse;
export type UpsertPtUserWorkingStatusRecordHandlerPartTimeUsersPtUserIdWorkingStatusRecordPostApiArg =
  {
    ptUserId: number;
    upsertPtUserWorkingStatusRecordRequest: UpsertPtUserWorkingStatusRecordRequest;
  };
export type ListPtUserWorkingStatusRecordHandlerPartTimeUsersWorkingStatusRecordsPostApiResponse =
  /** status 200 Successful Response */ ListPtUserWorkingStatusRecordResponse;
export type ListPtUserWorkingStatusRecordHandlerPartTimeUsersWorkingStatusRecordsPostApiArg =
  {
    sort?: string | null;
    listPtUserWorkingStatusRecordRequest: ListPtUserWorkingStatusRecordRequest;
  };
export type CreatePtUserHandlerPartTimeUsersPostApiResponse =
  /** status 200 Successful Response */ CreatePtUserResponse;
export type CreatePtUserHandlerPartTimeUsersPostApiArg = {
  createPtUserRequest: CreatePtUserRequest;
};
export type CreatePtUserStationHandlerPartTimeUsersStationsPostApiResponse =
  /** status 200 Successful Response */ CreatePtUserStationResponse;
export type CreatePtUserStationHandlerPartTimeUsersStationsPostApiArg = {
  createPtUserStationRequest: CreatePtUserStationRequest;
};
export type CheckEidHandlerPartTimeUsersCheckEidGetApiResponse =
  /** status 200 Successful Response */ CheckEidResponse;
export type CheckEidHandlerPartTimeUsersCheckEidGetApiArg = {
  eid?: string;
};
export type DeletePtUserHandlerPartTimeUsersPtUserIdDeleteApiResponse =
  /** status 200 Successful Response */ DeletePtUserRepsonse;
export type DeletePtUserHandlerPartTimeUsersPtUserIdDeleteApiArg = {
  ptUserId: number;
};
export type GetPtUserByIdHandlerPartTimeUsersPtUserIdGetApiResponse =
  /** status 200 Successful Response */ GetPtUserByIdResponse;
export type GetPtUserByIdHandlerPartTimeUsersPtUserIdGetApiArg = {
  ptUserId: number;
};
export type UpdatePtUserHandlerPartTimeUsersPtUserIdPostApiResponse =
  /** status 200 Successful Response */ UpdatePtUserResponse;
export type UpdatePtUserHandlerPartTimeUsersPtUserIdPostApiArg = {
  ptUserId: number;
  updatePtUserRequest: UpdatePtUserRequest;
};
export type GetPtUsersRolesHandlerPartTimeUsersRolesGetApiResponse =
  /** status 200 Successful Response */ GetPtUserRolesResponse;
export type GetPtUsersRolesHandlerPartTimeUsersRolesGetApiArg = void;
export type GetCountByStationGroupsHandlerPartTimeUsersGetCountByStationGroupsGetApiResponse =
  /** status 200 Successful Response */ GetPtCountResponse;
export type GetCountByStationGroupsHandlerPartTimeUsersGetCountByStationGroupsGetApiArg =
  {
    ptRoleId?: number;
    ptRoleIds?: number[];
    stationId: number;
  };
export type GetCountByStationsHandlerPartTimeUsersGetPtCountByStationsPostApiResponse =
  /** status 200 Successful Response */ GetPtCountByStationsResponse;
export type GetCountByStationsHandlerPartTimeUsersGetPtCountByStationsPostApiArg =
  {
    ptRoleId?: number | null;
    ptRoleIds?: number[];
    getPtCountByStationsRequest: GetPtCountByStationsRequest;
  };
export type GetTotalCountByStationsHandlerPartTimeUsersGetTotalPtCountByStationsGetApiResponse =
  /** status 200 Successful Response */ GetTotalPtCountByStationsResponse;
export type GetTotalCountByStationsHandlerPartTimeUsersGetTotalPtCountByStationsGetApiArg =
  {
    stationIds?: number[] | null;
    isFilterNotConsent?: boolean | null;
  };
export type ListByStationsHandlerPartTimeUsersListByStationsGetApiResponse =
  /** status 200 Successful Response */ ListPtUsersResponse;
export type ListByStationsHandlerPartTimeUsersListByStationsGetApiArg = {
  stationIds?: number[];
  ptRoleId?: number;
  showAll?: boolean | null;
  pageIndex?: number;
  pageSize?: number;
  sort?: string | null;
};
export type BatchSendWelcomeMessageHandlerPartTimeUsersBatchSendWelcomeMessagePostApiResponse =
  /** status 200 Successful Response */ BatchSendWelcomeMessageResponse;
export type BatchSendWelcomeMessageHandlerPartTimeUsersBatchSendWelcomeMessagePostApiArg =
  {
    batchSendWelcomeMessageRequest: BatchSendWelcomeMessageRequest;
  };
export type GetPtUsersHandlerPartTimeUsersListPostApiResponse =
  /** status 200 Successful Response */ GetPtUsersResponse;
export type GetPtUsersHandlerPartTimeUsersListPostApiArg = {
  searchString?: string | null;
  pageIndex?: number;
  pageSize?: number;
  sort?: string | null;
  getPtUsersRequest: GetPtUsersRequest;
};
export type ReactivatePtUserHandlerPartTimeUsersPtUserIdReactivatePostApiResponse =
  /** status 200 Successful Response */ ReactivatePtUserRepsonse;
export type ReactivatePtUserHandlerPartTimeUsersPtUserIdReactivatePostApiArg = {
  ptUserId: number;
};
export type WelcomePtUserHandlerPartTimeUsersPtUserIdWelcomePostApiResponse =
  /** status 200 Successful Response */ any;
export type WelcomePtUserHandlerPartTimeUsersPtUserIdWelcomePostApiArg = {
  ptUserId: number;
};
export type GetPtUserShiftsHandlerPartTimeUsersPtUserIdShiftsGetApiResponse =
  /** status 200 Successful Response */ GetPtUserShiftsResponse;
export type GetPtUserShiftsHandlerPartTimeUsersPtUserIdShiftsGetApiArg = {
  ptUserId: number;
  startBefore?: string | null;
  endAfter?: string | null;
};
export type SchedulePtUserSuspensionHandlerPartTimeUsersPtUserIdScheduleSuspensionPostApiResponse =
  /** status 200 Successful Response */ SchedulePtUserSuspensionResponse;
export type SchedulePtUserSuspensionHandlerPartTimeUsersPtUserIdScheduleSuspensionPostApiArg =
  {
    ptUserId: number;
    schedulePtUserSuspensionRequest: SchedulePtUserSuspensionRequest;
  };
export type ListPtUserShiftCancelByShiftHandlerPtUserShiftCancelsShiftIdGetApiResponse =
  /** status 200 Successful Response */ ListPtUserShiftCancelByShiftResponse;
export type ListPtUserShiftCancelByShiftHandlerPtUserShiftCancelsShiftIdGetApiArg =
  {
    shiftId: number;
    pageIndex?: number;
    pageSize?: number;
  };
export type ListShiftCompleteReasonsHandlerShiftCompleteReasonsGetApiResponse =
  /** status 200 Successful Response */ GetListShiftCompleteReasonsResponse;
export type ListShiftCompleteReasonsHandlerShiftCompleteReasonsGetApiArg = void;
export type ExportAllShiftsHandlerShiftsExportAllPostApiResponse =
  /** status 204 Successful Response */ undefined;
export type ExportAllShiftsHandlerShiftsExportAllPostApiArg = void;
export type ListShiftCancelReasonsHandlerShiftsCancelReasonsGetApiResponse =
  /** status 200 Successful Response */ GetListShiftCancelReasonsResponse;
export type ListShiftCancelReasonsHandlerShiftsCancelReasonsGetApiArg = void;
export type ListBorrowingRequestsHandlerShiftsBorrowingShiftRequestsGetApiResponse =
  /** status 200 Successful Response */ ListBorrowingRequestsResponse;
export type ListBorrowingRequestsHandlerShiftsBorrowingShiftRequestsGetApiArg =
  {
    stationId?: number | null;
    borrowingStatus?: BorrowingRequestStatus[];
    pageIndex?: number;
    pageSize?: number;
    sort?: string | null;
  };
export type HireHandlerShiftsShiftIdHirePostApiResponse =
  /** status 200 Successful Response */ HireRequestResponse;
export type HireHandlerShiftsShiftIdHirePostApiArg = {
  shiftId: number;
  hireRequestParam: HireRequestParam;
};
export type CancelHireHandlerShiftsShiftIdCancelHirePtUserPtUserIdPostApiResponse =
  /** status 200 Successful Response */ CancelHireResponse;
export type CancelHireHandlerShiftsShiftIdCancelHirePtUserPtUserIdPostApiArg = {
  ptUserId: number;
  shiftId: number;
};
export type ContactHandlerShiftsShiftIdContactedPtUserPtUserIdPostApiResponse =
  /** status 200 Successful Response */ ContactRequestResponse;
export type ContactHandlerShiftsShiftIdContactedPtUserPtUserIdPostApiArg = {
  shiftId: number;
  ptUserId: number;
  isPartnerContact: boolean;
};
export type GetChatHandlerShiftsShiftIdChatWithPtuserPtUserIdGetApiResponse =
  /** status 200 Successful Response */ GetChatResponse2;
export type GetChatHandlerShiftsShiftIdChatWithPtuserPtUserIdGetApiArg = {
  shiftId: number;
  ptUserId: number;
};
export type CreateShiftHandlerShiftsPostApiResponse =
  /** status 200 Successful Response */ CreateShiftResponse;
export type CreateShiftHandlerShiftsPostApiArg = {
  createShiftRequest: CreateShiftRequest;
};
export type ListShiftsHandlerShiftsGetApiResponse =
  /** status 200 Successful Response */ GetListShiftsResponse;
export type ListShiftsHandlerShiftsGetApiArg = {
  workingStationId?: number[] | null;
  completionFilter?: ClosedShiftCompletionStatus;
  year?: number | null;
  month?: number | null;
  before?: string | null;
  after?: string | null;
  status?: ShiftStatus | null;
  shiftType?: ShiftType | null;
  searchString?: string | null;
  ptUserRoleIds?: number[] | null;
  rateId?: number | null;
  pageIndex?: number;
  pageSize?: number;
  sort?: string | null;
};
export type BatchCancelShiftsHandlerShiftsBatchCancelPostApiResponse =
  /** status 200 Successful Response */ BatchCancelShiftResponse;
export type BatchCancelShiftsHandlerShiftsBatchCancelPostApiArg = {
  batchCancelShiftRequest: BatchCancelShiftRequest;
};
export type SendShiftRequestsHandlerShiftsShiftIdRequestsPostApiResponse =
  /** status 200 Successful Response */ any;
export type SendShiftRequestsHandlerShiftsShiftIdRequestsPostApiArg = {
  shiftId: number;
  sendShiftRequestParam: SendShiftRequestParam;
};
export type ListShiftRequestsWithNumberingHandlerShiftsShiftIdRequestsGetApiResponse =
  /** status 200 Successful Response */ ListShiftRequestWithNumberingResponse;
export type ListShiftRequestsWithNumberingHandlerShiftsShiftIdRequestsGetApiArg =
  {
    shiftId: number;
    statusList?: ShiftRequestStatus[] | null;
    includeStationIds?: number[] | null;
    excludeStationIds?: number[] | null;
    ptUser418Status?: Weekly418Status | null;
    shouldShowDeleted?: boolean | null;
    pageIndex?: number;
    pageSize?: number;
    sort?: string | null;
  };
export type GetAvailablePtUserHandlerShiftsAvailablePtUsersGetApiResponse =
  /** status 200 Successful Response */ GetAvailablePtUsersResponse;
export type GetAvailablePtUserHandlerShiftsAvailablePtUsersGetApiArg = {
  filterType: ShiftStationFilterType;
  showAll?: boolean | null;
  status418?: Weekly418Status | null;
  shiftId: number;
};
export type GetAvailablePtCountHandlerShiftsAvailablePtCountGetApiResponse =
  /** status 200 Successful Response */ GetAvailablePtCountResponse;
export type GetAvailablePtCountHandlerShiftsAvailablePtCountGetApiArg = {
  status418?: Weekly418Status | null;
  shiftId: number;
};
export type GetAvailablePtUserByStationsHandlerShiftsAvailablePtUsersByStationsGetApiResponse =
  /** status 200 Successful Response */ GetAvailablePtUserIdsResponse;
export type GetAvailablePtUserByStationsHandlerShiftsAvailablePtUsersByStationsGetApiArg =
  {
    stationIds?: number[];
    showAll?: boolean | null;
    status418?: Weekly418Status | null;
    shiftId: number;
  };
export type GetAvailablePtCountByStationsGroupsHandlerShiftsAvailablePtCountByStationsPostApiResponse =
  /** status 200 Successful Response */ GetAvailablePtCountByStationsGroupsResponse;
export type GetAvailablePtCountByStationsGroupsHandlerShiftsAvailablePtCountByStationsPostApiArg =
  {
    status418?: Weekly418Status | null;
    shiftId: number;
    getAvailablePtCountByStationsGroupsRequest: GetAvailablePtCountByStationsGroupsRequest;
  };
export type GetAvailablePtCountByStationsHandlerShiftsAvailablePtCountByStationsGetApiResponse =
  /** status 200 Successful Response */ GetAvailablePtCountByStationsResponse;
export type GetAvailablePtCountByStationsHandlerShiftsAvailablePtCountByStationsGetApiArg =
  {
    stationIds?: number[];
    status418?: Weekly418Status | null;
    shiftId: number;
  };
export type GetShiftByIdHandlerShiftsShiftIdGetApiResponse =
  /** status 200 Successful Response */ GetShiftByIdResponse;
export type GetShiftByIdHandlerShiftsShiftIdGetApiArg = {
  shiftId: number;
};
export type EditShiftHandlerShiftsShiftIdPostApiResponse =
  /** status 200 Successful Response */ EditShiftResponse;
export type EditShiftHandlerShiftsShiftIdPostApiArg = {
  shiftId: number;
  editShiftRequest: EditShiftRequest;
};
export type MakeExpireShiftHandlerShiftsShiftIdMakeExpirePostApiResponse =
  /** status 200 Successful Response */ MakeExpireShiftResponse;
export type MakeExpireShiftHandlerShiftsShiftIdMakeExpirePostApiArg = {
  shiftId: number;
};
export type BorrowingRequestHandlerShiftsRequestsShiftIdPtUserIdBorrowingPostApiResponse =
  /** status 200 Successful Response */ BorrowingRequestResponse;
export type BorrowingRequestHandlerShiftsRequestsShiftIdPtUserIdBorrowingPostApiArg =
  {
    shiftId: number;
    ptUserId: number;
    borrowingRequestParam: BorrowingRequestParam;
  };
export type ListStationHandlerStationsGetApiResponse =
  /** status 200 Successful Response */ ListStationResponse;
export type ListStationHandlerStationsGetApiArg = {
  q?: string | null;
  pageIndex?: number;
  pageSize?: number;
};
export type ListByShiftHandlerStationsListByShiftShiftIdGetApiResponse =
  /** status 200 Successful Response */ ListStationsResponse;
export type ListByShiftHandlerStationsListByShiftShiftIdGetApiArg = {
  shiftId: number;
  filterType: ShiftStationFilterType;
};
export type GetWorkingStationHandlerWorkingStationsIdGetApiResponse =
  /** status 200 Successful Response */ WorkingStationResponse;
export type GetWorkingStationHandlerWorkingStationsIdGetApiArg = {
  id: number;
};
export type ListWorkingStationHandlerWorkingStationsGetApiResponse =
  /** status 200 Successful Response */ ListWorkingStationResponse;
export type ListWorkingStationHandlerWorkingStationsGetApiArg = {
  q?: string | null;
  pageIndex?: number;
  pageSize?: number;
};
export type ListTemplateMessageHandlerTemplateMessageGetApiResponse =
  /** status 200 Successful Response */ ListTemplateMessageResponse;
export type ListTemplateMessageHandlerTemplateMessageGetApiArg = void;
export type TestSendHandlerTemplateMessageTestSendPostApiResponse =
  /** status 200 Successful Response */ string;
export type TestSendHandlerTemplateMessageTestSendPostApiArg = {
  testRequest: TestRequest;
};
export type ListIncidentContentTemplateHandlerIncidentContentTemplatesGetApiResponse =
  /** status 200 Successful Response */ ListIncidentContentTemplateResponse;
export type ListIncidentContentTemplateHandlerIncidentContentTemplatesGetApiArg =
  void;
export type GenerateTwilioTokenHandlerTwilioTokenPostApiResponse =
  /** status 200 Successful Response */ any;
export type GenerateTwilioTokenHandlerTwilioTokenPostApiArg = void;
export type MessageReceivedEventHandlerWebhookTwilioMessagePostApiResponse =
  /** status 200 Successful Response */ any;
export type MessageReceivedEventHandlerWebhookTwilioMessagePostApiArg = {
  "X-Twilio-Signature": string;
  bodyMessageReceivedEventHandlerWebhookTwilioMessagePost: BodyMessageReceivedEventHandlerWebhookTwilioMessagePost;
};
export type StatusUpdateEventHandlerWebhookTwilioStatusPostApiResponse =
  /** status 200 Successful Response */ any;
export type StatusUpdateEventHandlerWebhookTwilioStatusPostApiArg = {
  "X-Twilio-Signature": string;
  bodyStatusUpdateEventHandlerWebhookTwilioStatusPost: BodyStatusUpdateEventHandlerWebhookTwilioStatusPost;
};
export type ListNotificationsHandlerNotificationsGetApiResponse =
  /** status 200 Successful Response */ GetListNotificationsResponse;
export type ListNotificationsHandlerNotificationsGetApiArg = {
  stationId?: number | null;
  pageIndex?: number;
  pageSize?: number;
  sort?: string | null;
};
export type ReadNotificationHandlerNotificationsNotificationIdReadPostApiResponse =
  /** status 200 Successful Response */ ReadNotificationsResponse;
export type ReadNotificationHandlerNotificationsNotificationIdReadPostApiArg = {
  notificationId: number;
};
export type CreateAssetHandlerAssetsPostApiResponse =
  /** status 200 Successful Response */ CreateAssetResponse;
export type CreateAssetHandlerAssetsPostApiArg = {
  createAssetRequest: CreateAssetRequest;
};
export type GenerateTokenHandlerTokenGetApiResponse =
  /** status 200 Successful Response */ GenerateTokenResponse;
export type GenerateTokenHandlerTokenGetApiArg = {
  msalAccessToken: string;
};
export type GenerateWebsocketTokenHandlerTokenWebsocketGetApiResponse =
  /** status 200 Successful Response */ GenerateWebsocketTokenResponse;
export type GenerateWebsocketTokenHandlerTokenWebsocketGetApiArg = void;
export type ValidateInviteHandlerUsersValidateInviteGetApiResponse =
  /** status 200 Successful Response */ GetUserResponse;
export type ValidateInviteHandlerUsersValidateInviteGetApiArg = {
  email: string;
};
export type InviteManagerToStationHandlerUsersInviteUserIdPostApiResponse =
  /** status 200 Successful Response */ GetUserResponse;
export type InviteManagerToStationHandlerUsersInviteUserIdPostApiArg = {
  userId: number;
  inviteUserRequest: InviteUserRequest;
};
export type LeaveStationHandlerUsersLeaveStationPostApiResponse =
  /** status 200 Successful Response */ any;
export type LeaveStationHandlerUsersLeaveStationPostApiArg = {
  leaveStationRequest: LeaveStationRequest;
};
export type CreateUserHandlerUsersPostApiResponse =
  /** status 200 Successful Response */ CreateUserResponse;
export type CreateUserHandlerUsersPostApiArg = {
  createUserRequest: CreateUserRequest;
};
export type ListUserHandlerUsersGetApiResponse =
  /** status 200 Successful Response */ ListUserResponse;
export type ListUserHandlerUsersGetApiArg = {
  role?: UserAdminRole | null;
  stationId?: number | null;
  railwayLineId?: number | null;
  pageIndex?: number;
  pageSize?: number;
};
export type EditUserHandlerUsersUserIdPostApiResponse =
  /** status 200 Successful Response */ EditUserResponse;
export type EditUserHandlerUsersUserIdPostApiArg = {
  userId: number;
  editUserRequest: EditUserRequest;
};
export type GetUserHandlerUsersUserIdGetApiResponse =
  /** status 200 Successful Response */ GetUserResponse;
export type GetUserHandlerUsersUserIdGetApiArg = {
  userId: number;
};
export type ListUserStationInviteRecordHandlerUserStationInviteRecordsGetApiResponse =
  /** status 200 Successful Response */ ListUserStationInviteRecordResponse;
export type ListUserStationInviteRecordHandlerUserStationInviteRecordsGetApiArg =
  void;
export type ReadUserStationInviteRecordHandlerUserStationInviteRecordsReadPostApiResponse =
  /** status 200 Successful Response */ ListUserStationInviteRecordResponse;
export type ReadUserStationInviteRecordHandlerUserStationInviteRecordsReadPostApiArg =
  void;
export type SendMasterShiftInvitationHandlerMasterShiftsMasterShiftIdInvitePostApiResponse =
  /** status 200 Successful Response */ any;
export type SendMasterShiftInvitationHandlerMasterShiftsMasterShiftIdInvitePostApiArg =
  {
    masterShiftId: number;
    sendMasterShiftInvitationRequest: SendMasterShiftInvitationRequest;
  };
export type GetMasterShiftRequestCountHandlerMasterShiftsMasterShiftIdRequestCountGetApiResponse =
  /** status 200 Successful Response */ GetMasterShiftRequestCountResponse;
export type GetMasterShiftRequestCountHandlerMasterShiftsMasterShiftIdRequestCountGetApiArg =
  {
    masterShiftId: number;
  };
export type GetCountByStationGroupsHandlerMasterShiftsGetCountByStationGroupsGetApiResponse =
  /** status 200 Successful Response */ GetPtCountResponse;
export type GetCountByStationGroupsHandlerMasterShiftsGetCountByStationGroupsGetApiArg =
  {
    ptRoleId?: number;
    ptRoleIds?: number[];
    masterShiftId?: number;
    stationId: number;
  };
export type GetAvailablePtUserByStationsHandlerMasterShiftsAvailablePtUsersByStationsGetApiResponse =
  /** status 200 Successful Response */ GetJobAvailablePtUserIdsResponse;
export type GetAvailablePtUserByStationsHandlerMasterShiftsAvailablePtUsersByStationsGetApiArg =
  {
    masterShiftId?: number;
    stationIds?: number[];
    ptRoleId?: number;
    ptRoleIds?: number[];
    showAll?: boolean | null;
    pageIndex?: number;
    pageSize?: number;
    sort?: string | null;
  };
export type GetMasterShiftHandlerMasterShiftsMasterShiftIdGetApiResponse =
  /** status 200 Successful Response */ GetMasterShiftResponse;
export type GetMasterShiftHandlerMasterShiftsMasterShiftIdGetApiArg = {
  masterShiftId: number;
};
export type CreateMasterShiftHandlerMasterShiftsPostApiResponse =
  /** status 200 Successful Response */ CreateMasterShiftResponse;
export type CreateMasterShiftHandlerMasterShiftsPostApiArg = {
  createMasterShiftRequest: CreateMasterShiftRequest;
};
export type ListStationTeamHandlerStationTeamsGetApiResponse =
  /** status 200 Successful Response */ ListStationTeamResponse;
export type ListStationTeamHandlerStationTeamsGetApiArg = void;
export type ListRailwayLineHandlerRailwayLinesGetApiResponse =
  /** status 200 Successful Response */ ListRailwayLineResponse;
export type ListRailwayLineHandlerRailwayLinesGetApiArg = void;
export type ListPublicHolidaysHandlerPublicHolidaysGetApiResponse =
  /** status 200 Successful Response */ GetListPublicHolidayResponse;
export type ListPublicHolidaysHandlerPublicHolidaysGetApiArg = {
  startDate?: string | null;
  endDate?: string | null;
};
export type ListShiftRateHandlerShiftRatesGetApiResponse =
  /** status 200 Successful Response */ ListShiftRateResponse;
export type ListShiftRateHandlerShiftRatesGetApiArg = void;
export type ListShiftRequestHandlerShiftRequestsGetApiResponse =
  /** status 200 Successful Response */ ListShiftRequestResponse;
export type ListShiftRequestHandlerShiftRequestsGetApiArg = {
  isOut?: boolean | null;
  shiftWorkingStationId?: number[] | null;
  shiftBefore?: string | null;
  shiftAfter?: string | null;
  shiftStatus?: ShiftStatus[] | null;
  shiftSearchString?: string | null;
  shiftRateId?: number[] | null;
  ptStationId?: number[] | null;
  ptSearchString?: string | null;
  ptRoleId?: number[] | null;
  shiftRequestStatus?: ShiftRequestStatus[] | null;
  sort?: string | null;
  pageIndex?: number;
  pageSize?: number;
};
export type ExportShiftRequestHandlerShiftRequestsExportPostApiResponse =
  /** status 204 Successful Response */ undefined;
export type ExportShiftRequestHandlerShiftRequestsExportPostApiArg = {
  isOut?: boolean | null;
  sort?: string | null;
  shiftWorkingStationId?: number[] | null;
  shiftBefore?: string | null;
  shiftAfter?: string | null;
  shiftStatus?: ShiftStatus[] | null;
  shiftSearchString?: string | null;
  shiftRateId?: number[] | null;
  ptStationId?: number[] | null;
  ptSearchString?: string | null;
  ptRoleId?: number[] | null;
  shiftRequestStatus?: ShiftRequestStatus[] | null;
};
export type AddShiftFullTimeAttendanceHandlerShiftFullTimeAttendancesPostApiResponse =
  /** status 200 Successful Response */ CreateShiftFullTimeAttendanceResponse;
export type AddShiftFullTimeAttendanceHandlerShiftFullTimeAttendancesPostApiArg =
  {
    shiftId?: number;
    createShiftFullTimeAttendanceRequest: CreateShiftFullTimeAttendanceRequest;
  };
export type ListShiftFullTimeAttendanceByShiftHandlerShiftFullTimeAttendancesGetApiResponse =
  /** status 200 Successful Response */ ListShiftFullTimeAttendanceResponse;
export type ListShiftFullTimeAttendanceByShiftHandlerShiftFullTimeAttendancesGetApiArg =
  {
    shiftId?: number;
  };
export type RemoveShiftFullTimeAttendanceHandlerShiftFullTimeAttendancesAttendanceIdDeleteApiResponse =
  /** status 200 Successful Response */ ShiftFullTimeAttendanceResponse;
export type RemoveShiftFullTimeAttendanceHandlerShiftFullTimeAttendancesAttendanceIdDeleteApiArg =
  {
    attendanceId: number;
  };
export type ExportUsageHandlerWhatsappUsageExportPostApiResponse =
  /** status 204 Successful Response */ undefined;
export type ExportUsageHandlerWhatsappUsageExportPostApiArg = {
  generateUsageRequest: GenerateUsageRequest;
};
export type ApplyShiftHandlerPtShiftsShiftIdApplyPostApiResponse =
  /** status 200 Successful Response */ PtPostShiftApplicationResponse;
export type ApplyShiftHandlerPtShiftsShiftIdApplyPostApiArg = {
  shiftId: number;
};
export type PtUserListShiftsRateByDateHandlerPtShiftsListShiftRateByDateGetApiResponse =
  /** status 200 Successful Response */ PtGetListShiftRateByDateResponse;
export type PtUserListShiftsRateByDateHandlerPtShiftsListShiftRateByDateGetApiArg =
  {
    startBefore?: string | null;
    endAfter?: string | null;
    shiftRequestStatuses?: ShiftRequestStatus[] | null;
    borrowingShiftStatuses?: BorrowingRequestStatus[] | null;
    workingStationId?: number[] | null;
    showNoneStationIds?: number[] | null;
    shiftStatus?: ShiftStatus[] | null;
    excludeNonBookmarkedShifts?: boolean | null;
    masterShiftId?: number[] | null;
    shiftType?: ShiftType | null;
  };
export type CancelApplyShiftHandlerPtShiftsShiftIdCancelApplyPostApiResponse =
  /** status 200 Successful Response */ PtCancelApplyShiftResponse;
export type CancelApplyShiftHandlerPtShiftsShiftIdCancelApplyPostApiArg = {
  shiftId: number;
};
export type GetShiftApplicationHandlerPtShiftsApplicationGetApiResponse =
  /** status 200 Successful Response */ GetShiftApplicationResponse;
export type GetShiftApplicationHandlerPtShiftsApplicationGetApiArg = {
  token: string;
};
export type GetUnreadShiftCountHandlerPtShiftsPtUnreadShiftCountGetApiResponse =
  /** status 200 Successful Response */ GetUnreadShiftCountResponse;
export type GetUnreadShiftCountHandlerPtShiftsPtUnreadShiftCountGetApiArg =
  void;
export type PtUserListShiftsHandlerPtShiftsListGetApiResponse =
  /** status 200 Successful Response */ PtGetListShiftsResponse;
export type PtUserListShiftsHandlerPtShiftsListGetApiArg = {
  startBefore?: string | null;
  endAfter?: string | null;
  shiftRequestStatuses?: ShiftRequestStatus[] | null;
  borrowingShiftStatuses?: BorrowingRequestStatus[] | null;
  workingStationId?: number[] | null;
  showNoneStationIds?: number[] | null;
  shiftStatus?: ShiftStatus[] | null;
  excludeNonBookmarkedShifts?: boolean | null;
  masterShiftId?: number[] | null;
  shiftType?: ShiftType | null;
  pageIndex?: number;
  pageSize?: number;
  sort?: string | null;
};
export type MarkShiftReadHandlerPtShiftsShiftIdMarkReadPostApiResponse =
  /** status 200 Successful Response */ GetShiftResponse;
export type MarkShiftReadHandlerPtShiftsShiftIdMarkReadPostApiArg = {
  shiftId: number;
};
export type GetShiftByIdHandlerPtShiftsShiftIdGetApiResponse =
  /** status 200 Successful Response */ PtGetShiftByIdResponse;
export type GetShiftByIdHandlerPtShiftsShiftIdGetApiArg = {
  shiftId: number;
};
export type LikeShiftHandlerPtShiftsShiftIdLikePostApiResponse =
  /** status 200 Successful Response */ LikeShiftResponse;
export type LikeShiftHandlerPtShiftsShiftIdLikePostApiArg = {
  shiftId: number;
};
export type UnlikeShiftHandlerPtShiftsShiftIdUnlikePostApiResponse =
  /** status 200 Successful Response */ UnlikeShiftResponse;
export type UnlikeShiftHandlerPtShiftsShiftIdUnlikePostApiArg = {
  shiftId: number;
};
export type BookmarkShiftHandlerPtShiftsShiftIdBookmarkPostApiResponse =
  /** status 200 Successful Response */ BookmarkShiftResponse;
export type BookmarkShiftHandlerPtShiftsShiftIdBookmarkPostApiArg = {
  shiftId: number;
};
export type UnbookmarkShiftHandlerPtShiftsShiftIdUnbookmarkPostApiResponse =
  /** status 200 Successful Response */ UnbookmarkShiftResponse;
export type UnbookmarkShiftHandlerPtShiftsShiftIdUnbookmarkPostApiArg = {
  shiftId: number;
};
export type GetShiftRequestByIdHandlerPtShiftRequestsShiftsShiftIdGetApiResponse =
  /** status 200 Successful Response */ PtGetShiftRequestByIdResponse;
export type GetShiftRequestByIdHandlerPtShiftRequestsShiftsShiftIdGetApiArg = {
  shiftId: number;
};
export type MarkShiftRequestReadHandlerPtShiftRequestsMarkReadPostApiResponse =
  /** status 200 Successful Response */ GetShiftRequestResponse;
export type MarkShiftRequestReadHandlerPtShiftRequestsMarkReadPostApiArg = {
  markShiftRequestsReadRequest: MarkShiftRequestsReadRequest;
};
export type PtUserAuthenticateHandlerPtPtUserAuthenticateGetApiResponse =
  /** status 200 Successful Response */ PtUser;
export type PtUserAuthenticateHandlerPtPtUserAuthenticateGetApiArg = void;
export type GetMasterShiftIdFromApplicationHandlerPtMasterShiftsIdFromApplicationGetApiResponse =
  /** status 200 Successful Response */ GetMasterShiftIdResponse;
export type GetMasterShiftIdFromApplicationHandlerPtMasterShiftsIdFromApplicationGetApiArg =
  {
    token: string;
  };
export type ApplyMasterShiftByIdHandlerPtMasterShiftsJobIdApplyPostApiResponse =
  /** status 200 Successful Response */ ApplyMasterShiftResponse;
export type ApplyMasterShiftByIdHandlerPtMasterShiftsJobIdApplyPostApiArg = {
  jobId: number;
  applyMasterShiftRequest: ApplyMasterShiftRequest;
};
export type GetMasterShiftDetailHandlerPtMasterShiftsJobIdGetApiResponse =
  /** status 200 Successful Response */ GetMasterShiftApplicationResponse;
export type GetMasterShiftDetailHandlerPtMasterShiftsJobIdGetApiArg = {
  jobId: number;
};
export type ConsentPtUserHandlerPtPartTimeUsersPtUserIdConsentPostApiResponse =
  /** status 200 Successful Response */ ConsentPtUserResponse;
export type ConsentPtUserHandlerPtPartTimeUsersPtUserIdConsentPostApiArg = {
  ptUserId: number;
};
export type ListRailwayLinesHandlerPtRailwayLinesGetApiResponse =
  /** status 200 Successful Response */ ListRailwayLinesResponse;
export type ListRailwayLinesHandlerPtRailwayLinesGetApiArg = void;
export type ListFrontlineAppConfigHandlerPtFrontlineAppConfigGetApiResponse =
  /** status 200 Successful Response */ FrontlineAppConfig;
export type ListFrontlineAppConfigHandlerPtFrontlineAppConfigGetApiArg = void;
export type GetPtUserShiftCancelHandlerPtPtUserShiftCancelShiftIdGetApiResponse =
  /** status 200 Successful Response */ GetPtUserShiftCancelResponse;
export type GetPtUserShiftCancelHandlerPtPtUserShiftCancelShiftIdGetApiArg = {
  shiftId: number;
};
export type ListCustomerRailwayLinesHandlerPtCustomerRailwayLinesGetApiResponse =
  /** status 200 Successful Response */ ListRailwayLinesResponse2;
export type ListCustomerRailwayLinesHandlerPtCustomerRailwayLinesGetApiArg =
  void;
export type AdminAppConfig = {
  shouldShowGreen418Badge: boolean;
  shouldShowIncidentFeature: boolean;
  shouldShowT3Feature: boolean;
  allStationsFilterEnabledStations: string;
  enableCcoRole: boolean;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type AnnouncementType = "FRIENDLY_REMINDER" | "IMPORTANT";
export type Asset = {
  id: number;
  key: string;
  url: string | null;
  filename: string;
  contentType: string;
  contentSize: number | null;
};
export type Announcement = {
  id: number;
  content: string;
  type: AnnouncementType;
  recipient: string;
  senderEmail: string;
  sentAt: string;
  attachment?: Asset | null;
};
export type CreateAnnouncementResponse = {
  announcement: Announcement;
};
export type CreateAnnouncementRequest = {
  type: AnnouncementType;
  content: string;
  attachmentId?: number | null;
  stations?: number[] | null;
  line?: number | null;
  isSendAll?: boolean | null;
};
export type ListAnnouncementResponse = {
  totalCount: number;
  nextPageIndex: number | null;
  results: Announcement[];
};
export type GetAnnouncementByIdResponse = {
  announcement: Announcement;
};
export type UserAdminRole = "MANAGER" | "LANDLORD" | "ADMIN";
export type LocalizedString = {
  zhHk: string | null;
  en: string | null;
};
export type WorkingStation = {
  id: number;
  name: LocalizedString;
  shortCode: string;
  colorHexcode: string;
  mainStationId: number;
};
export type RelativeStation = {
  id: number;
  shortCode: string;
  workingStations: WorkingStation[];
};
export type RailwayLine = {
  id: number;
  name: LocalizedString;
  shortCode: string;
  colorHexcode: string;
};
export type Station = {
  id: number;
  name: LocalizedString;
  shortCode: string;
  workingStations: WorkingStation[];
  relativeStations: RelativeStation[];
  lines: RailwayLine[];
  colorHexcode: string | null;
};
export type BriefStationWithWorkingStation = {
  id: number;
  name: LocalizedString;
  shortCode: string;
  colorHexcode: string | null;
  workingStations: WorkingStation[];
};
export type UserStationProfile = {
  userId: number;
  station: BriefStationWithWorkingStation;
};
export type MeUser = {
  id: number;
  adminRole: UserAdminRole;
  name: string;
  nameZhHk: string;
  phoneNumber: string;
  profilePic: Asset | null;
  email: string;
  azureId: string;
  stations: Station[];
  languagePreference: string;
  selectedProfile?: UserStationProfile | null;
  railwayLine?: RailwayLine | null;
};
export type User = {
  id: number;
  adminRole: string;
  name: string;
  nameZhHk: string;
  email: string;
  profilePic: Asset | null;
};
export type PtUserEmploymentStatus =
  | "active"
  | "pendingSuspension"
  | "inactive";
export type RegularShiftSchedule = {
  dayOfWeek: number;
  startTime: string;
  endTime: string;
};
export type WhatsappConnection = {
  id: number;
  whatsappId: string;
  phoneNumber: string;
};
export type PtUserRole = {
  id: number;
  name: string;
};
export type WeekOfYear = {
  year: number;
  weekNumber: number;
};
export type Weekly418Status = "SAFE" | "WARNING" | "UNKNOWN";
export type WeekOfYear418Status = {
  weekOfYear: WeekOfYear;
  status: Weekly418Status;
};
export type StationTeam = {
  id: number;
  stationId: number;
  teamCode: string;
  colorHexcode: string;
};
export type PtUser = {
  id: number;
  firstNameEn: string;
  lastNameEn: string;
  fullNameZhHk: string;
  eid: string;
  email: string | null;
  countryCode: string;
  phoneNumber: string;
  startWorkDate: string;
  isDeleted: boolean;
  pendingConsent: boolean;
  receiveInviteOnHoliday: boolean;
  employmentStatus: PtUserEmploymentStatus;
  regularShiftSchedule?: RegularShiftSchedule[] | null;
  profilePic?: Asset | null;
  station?: Station | null;
  whatsappConnection?: WhatsappConnection | null;
  role?: PtUserRole | null;
  currentWeek418Status?: WeekOfYear418Status | null;
  scheduledSuspendAt?: string | null;
  stationTeam?: StationTeam | null;
};
export type AttachmentType = "image" | "audio" | "video" | "document";
export type MessageAttachment = {
  id: string;
  asset: Asset;
  attachmentType: AttachmentType;
};
export type Message = {
  id: number;
  type: "chat" | "system";
  content: string | null;
  isPublic: boolean;
  status: "delivered" | "sent" | "read" | "enqueued" | "received" | "failed";
  externalId: string | null;
  chatId: number;
  templateId: number | null;
  templateParam: object | null;
  createdAt: string;
  user: User | null;
  userStation: BriefStationWithWorkingStation | null;
  ptUser: PtUser | null;
  attachments: MessageAttachment[];
};
export type ListChatMessageResponse = {
  items: Message[];
  before: string | null;
  after: string | null;
};
export type UnreadChatCountResponse = {
  count: number;
};
export type Chat = {
  id: number;
  lastInboundMessageTimestamp: string | null;
  lastMessage: Message | null;
  ptUser: PtUser;
  station: BriefStationWithWorkingStation;
  partnerStation: BriefStationWithWorkingStation | null;
};
export type ChatUserStatus = {
  chatId: number;
  userId: number;
  stationId: number;
  lastReadAt: string | null;
};
export type GetChatResponse = {
  chat: Chat;
  readStatus: ChatUserStatus | null;
};
export type ListChatResponse = {
  items: Chat[];
  before: string | null;
  readStatus: {
    [key: string]: ChatUserStatus | null;
  };
};
export type SendTemplateMessageResponse = {
  message: Message;
};
export type SendTemplateMessageRequest = {
  templateId: number;
  templateParams: {
    [key: string]: string;
  };
  isPublic: boolean;
};
export type SendTextMessageResponse = {
  message: Message;
};
export type SendTextMessageRequest = {
  text: string;
  attachmentIds: number[];
  isPublic: boolean;
};
export type UpdateLanguagePreferenceRequest = {
  newLanguagePreference: string;
};
export type MeSwitchProfileResponse = {
  me: MeUser;
};
export type MeSwitchProfileRequest = {
  stationId: number;
};
export type WorkingStatus = "GTE18" | "LT18";
export type WorkingStatusRecord = {
  id: number;
  ptUserId: number;
  year: number;
  weekNumber: number;
  status: WorkingStatus;
};
export type UpsertPtUserWorkingStatusRecordResponse = {
  ptUserWorkingStatusRecord: WorkingStatusRecord;
};
export type UpsertPtUserWorkingStatusRecordRequest = {
  weekOfYear: WeekOfYear;
  status: WorkingStatus;
};
export type PtUserWorkingStatusWith418Status = {
  year: number;
  weekNumber: number;
  workingStatus: WorkingStatus | null;
  weekly418Status: Weekly418Status;
};
export type ListPtUserWorkingStatusRecordResponseItem = {
  ptUserId: number;
  workingStatusRecords: PtUserWorkingStatusWith418Status[];
};
export type ListPtUserWorkingStatusRecordResponse = {
  items: ListPtUserWorkingStatusRecordResponseItem[];
};
export type ListPtUserWorkingStatusRecordRequest = {
  ptUserIds: number[];
  offsetDirection: "after" | "before";
  numWeekOfYears: number;
  anchorWeekOfYear: WeekOfYear;
};
export type CreatePtUserResponse = {
  ptUser: PtUser;
};
export type CreatePtUserRequest = {
  firstNameEn: string;
  lastNameEn: string;
  fullNameZhHk: string;
  eid: string;
  email?: string | null;
  countryCode: string;
  phoneNumber: string;
  roleId: number;
  regularShiftSchedule: RegularShiftSchedule[];
  startWorkDate: string;
  stationId: number;
  stationTeamId?: number | null;
  receiveInviteOnHoliday?: boolean | null;
};
export type StationRole = {
  stationId: number;
  roleId: number;
};
export type CreatePtUserStationResponse = {
  ptUserId: number;
  stationRoles: StationRole[];
};
export type CreatePtUserStationRequest = {
  ptUserId: number;
  stationRoles: StationRole[];
};
export type CheckEidResponse = {
  exist: boolean;
  stationCode: string | null;
};
export type DeletePtUserRepsonse = {
  ptUserId: number;
};
export type GetPtUserByIdResponse = {
  ptUser: PtUser;
};
export type UpdatePtUserResponse = {
  ptUser: PtUser;
};
export type UpdatePtUserRequest = {
  firstNameEn?: string | null;
  lastNameEn?: string | null;
  fullNameZhHk?: string | null;
  eid?: string | null;
  email?: string | null;
  countryCode?: string | null;
  phoneNumber?: string | null;
  roleId?: number | null;
  regularShiftSchedule?: RegularShiftSchedule[] | null;
  startWorkDate?: string | null;
  stationTeamId?: number | null;
  receiveInviteOnHoliday?: boolean | null;
};
export type GetPtUserRolesResponse = {
  ptUserRoles: PtUserRole[];
};
export type GetPtCountResponse = {
  homeCount: number;
  relativeCount: number;
  lineCount: number;
  allCount: number;
};
export type GetPtCountByStationsResponse = {
  counts: {
    [key: string]: number;
  };
};
export type GetPtCountByStationsRequest = {
  stationsGroupsIdsMap?: {
    [key: string]: number[];
  };
};
export type GetTotalPtCountByStationsResponse = {
  totalCount: number;
};
export type ListPtUsersResponse = {
  totalCount: number;
  nextPageIndex: number | null;
  results: PtUser[];
};
export type BatchSendWelcomeMessageResponse = {
  succeedPtUserIds: number[];
  failedPtUserIds: number[];
};
export type BatchSendWelcomeMessageRequest = {
  stationIds: number[];
};
export type GetPtUsersResponse = {
  totalCount: number;
  nextPageIndex: number | null;
  results: PtUser[];
};
export type GetPtUsersRequest = {
  exceptUserIds?: number[] | null;
  stationIds?: number[] | null;
  isFilterNotConsent?: boolean | null;
  showDeleted?: boolean;
  ptRoleId?: number | null;
  ptRoleIds?: number[] | null;
};
export type ReactivatePtUserRepsonse = {
  ptUser: PtUser;
};
export type ShiftRate = {
  id: number;
  code: string;
  colorHexcode: string;
  desc: LocalizedString | null;
  shortDesc: LocalizedString | null;
  seq: number | null;
};
export type BriefStation = {
  id: number;
  name: LocalizedString;
  shortCode: string;
  colorHexcode: string | null;
};
export type WorkingStationWithMainStation = {
  id: number;
  name: LocalizedString;
  shortCode: string;
  colorHexcode: string;
  mainStationId: number;
  mainStation: BriefStation;
};
export type ShiftStatus = "active" | "closed" | "cancelled" | "expired";
export type ShiftType = "REGULAR" | "INCIDENT";
export type IncidentShiftAccessControl = {
  canView: boolean;
  canEdit: boolean;
  canCancel: boolean;
  canClose: boolean;
  canCancelHiredPt: boolean;
  canConfirmAppliedPt: boolean;
  canDirectHireOwnStationPt: boolean;
  canChatWithHiredPt: boolean;
};
export type ShiftFullTimeAttendance = {
  id: number;
  shiftId: number;
  station: BriefStation;
  name: LocalizedString;
  role: string;
};
export type Shift = {
  id: number;
  createdAt: string;
  displayId: string;
  fulfillmentCount: number;
  fulltimeCount: number;
  hiredCount: number;
  dutyStartTime: string;
  dutyEndTime: string | null;
  shiftTitle: string;
  shiftDescription: string;
  ptUserRoles: PtUserRole[];
  rate: ShiftRate | null;
  workingStationId: number;
  workingStation: WorkingStationWithMainStation;
  supportStationId: number | null;
  supportStation: WorkingStationWithMainStation | null;
  status: ShiftStatus;
  completeReason: LocalizedString | null;
  cancelReason: LocalizedString | null;
  masterShiftId: number;
  shiftType: ShiftType;
  incidentAccessControl?: IncidentShiftAccessControl | null;
  isIncident: boolean;
  isAppliableToAllAtT3: boolean;
  fullTimeAttendances?: ShiftFullTimeAttendance[] | null;
};
export type GetPtUserShiftsResponse = {
  shifts: Shift[];
};
export type SchedulePtUserSuspensionResponse = {
  ptUser: PtUser;
};
export type SchedulePtUserSuspensionRequest = {
  scheduledSuspendAt: string;
  isImmediate?: boolean | null;
};
export type PtUserVisitCount = {
  id: number;
  workingStationShortCode: string;
  visitCount: number;
};
export type PtUserWithVisitCount = {
  id: number;
  firstNameEn: string;
  lastNameEn: string;
  fullNameZhHk: string;
  eid: string;
  email: string | null;
  countryCode: string;
  phoneNumber: string;
  startWorkDate: string;
  isDeleted: boolean;
  pendingConsent: boolean;
  receiveInviteOnHoliday: boolean;
  employmentStatus: PtUserEmploymentStatus;
  regularShiftSchedule?: RegularShiftSchedule[] | null;
  profilePic?: Asset | null;
  station?: Station | null;
  whatsappConnection?: WhatsappConnection | null;
  role?: PtUserRole | null;
  currentWeek418Status?: WeekOfYear418Status | null;
  scheduledSuspendAt?: string | null;
  stationTeam?: StationTeam | null;
  visitCounts: PtUserVisitCount[] | null;
};
export type PtUserShiftCancelCountWithPtUser = {
  count: number;
  updatedAt: string;
  ptUser: PtUserWithVisitCount;
};
export type ListPtUserShiftCancelByShiftResponse = {
  totalCount: number;
  nextPageIndex: number | null;
  results: PtUserShiftCancelCountWithPtUser[];
};
export type ShiftCompleteReason = {
  id: number;
  description: LocalizedString;
};
export type GetListShiftCompleteReasonsResponse = {
  results: ShiftCompleteReason[];
};
export type ShiftCancelReason = {
  id: number;
  description: LocalizedString;
};
export type GetListShiftCancelReasonsResponse = {
  cancelReasons: ShiftCancelReason[];
};
export type ShiftRequestStatus =
  | "requested"
  | "applied"
  | "contacted"
  | "hired"
  | "rejected"
  | "completed";
export type BorrowingRequestStatus =
  | "pending"
  | "contacted"
  | "rejected"
  | "approved";
export type ShiftRequest = {
  shiftId: number;
  ptUserId: number;
  rejectReason: string | null;
  sentRequestCount: number;
  status: ShiftRequestStatus;
  borrowingRequestStatus: BorrowingRequestStatus | null;
  ptUser: PtUserWithVisitCount;
  chatId: number | null;
  createdAt: string;
  updatedAt: string;
  appliedAt: string | null;
  hiredAt: string | null;
  approvedAt: string | null;
  rejectedAt: string | null;
  ptUserShiftWeek418Status: Weekly418Status | null;
  lastReadAt: string | null;
  cancelCount: number | null;
  shift: Shift | null;
};
export type ListBorrowingRequestsResponse = {
  totalCount: number;
  nextPageIndex: number | null;
  results: ShiftRequest[];
  uncontactedCount: number;
};
export type HireRequestResponse = {
  hiredShiftRequests: ShiftRequest[];
};
export type HireRequestParam = {
  ptUserIds: number[];
  isDirectHire?: boolean;
};
export type ShiftRequestBase = {
  shiftId: number;
  ptUserId: number;
  rejectReason: string | null;
  sentRequestCount: number;
  status: ShiftRequestStatus;
  borrowingRequestStatus: BorrowingRequestStatus | null;
  ptUser: PtUserWithVisitCount;
  chatId: number | null;
  createdAt: string;
  updatedAt: string;
  appliedAt: string | null;
  hiredAt: string | null;
  approvedAt: string | null;
  rejectedAt: string | null;
  ptUserShiftWeek418Status: Weekly418Status | null;
  lastReadAt: string | null;
  cancelCount: number | null;
};
export type ShiftWithRequests = {
  id: number;
  createdAt: string;
  displayId: string;
  fulfillmentCount: number;
  fulltimeCount: number;
  hiredCount: number;
  dutyStartTime: string;
  dutyEndTime: string | null;
  shiftTitle: string;
  shiftDescription: string;
  ptUserRoles: PtUserRole[];
  rate: ShiftRate | null;
  workingStationId: number;
  workingStation: WorkingStationWithMainStation;
  supportStationId: number | null;
  supportStation: WorkingStationWithMainStation | null;
  status: ShiftStatus;
  completeReason: LocalizedString | null;
  cancelReason: LocalizedString | null;
  masterShiftId: number;
  shiftType: ShiftType;
  incidentAccessControl?: IncidentShiftAccessControl | null;
  isIncident: boolean;
  isAppliableToAllAtT3: boolean;
  fullTimeAttendances?: ShiftFullTimeAttendance[] | null;
  shiftRequests: ShiftRequestBase[];
};
export type CancelHireResponse = {
  shift: ShiftWithRequests;
};
export type ContactRequestResponse = {
  shiftRequest: ShiftRequest | null;
};
export type GetChatResponse2 = {
  chat: Chat;
};
export type CreateShiftResponse = {
  shift: Shift;
};
export type CreateShiftRequest = {
  fulfillmentCount: number;
  dutyStartTime: string;
  dutyEndTime: string | null;
  shiftTitle: string;
  shiftDescription: string;
  ptUserRoleId?: number | null;
  ptUserRoleIds: number[];
  workingStationId: number;
};
export type GetListShiftsResponse = {
  totalCount: number;
  nextPageIndex: number | null;
  results: ShiftWithRequests[];
};
export type ClosedShiftCompletionStatus = "completed" | "not_completed" | "all";
export type BatchCancelShiftResponse = {
  shifts: Shift[];
};
export type BatchCancelShiftRequest = {
  shiftIds: number[];
  cancelReasonId: number;
};
export type ShiftStationFilterType = "home" | "relative" | "line" | "all";
export type SendShiftRequestParam = {
  stationIds: number[];
  ptUserIds?: number[] | null;
  filterType?: ShiftStationFilterType | null;
};
export type ShiftRequestBaseWithNumbering = {
  shiftId: number;
  ptUserId: number;
  rejectReason: string | null;
  sentRequestCount: number;
  status: ShiftRequestStatus;
  borrowingRequestStatus: BorrowingRequestStatus | null;
  ptUser: PtUserWithVisitCount;
  chatId: number | null;
  createdAt: string;
  updatedAt: string;
  appliedAt: string | null;
  hiredAt: string | null;
  approvedAt: string | null;
  rejectedAt: string | null;
  ptUserShiftWeek418Status: Weekly418Status | null;
  lastReadAt: string | null;
  cancelCount: number | null;
  index: number;
};
export type PtUserWithClashedShift = {
  ptUser: PtUser;
  clashedShifts: Shift[];
};
export type ListShiftRequestWithNumberingResponse = {
  totalCount: number;
  nextPageIndex: number | null;
  results: ShiftRequestBaseWithNumbering[];
  ptUsersWithClashedShifts: PtUserWithClashedShift[];
};
export type GetAvailablePtUsersResponseShiftWeek418Status = {
  ptUserId: number;
  shiftWeek418Status: Weekly418Status;
};
export type GetAvailablePtUsersResponse = {
  totalCount: number;
  nextPageIndex: number | null;
  results: PtUser[];
  shiftWeek418Statuses: GetAvailablePtUsersResponseShiftWeek418Status[];
};
export type GetAvailablePtCountResponse = {
  homeCount: number;
  relativeCount: number;
  lineCount: number;
  allCount: number;
};
export type GetAvailablePtUserIdsResponse = {
  ptUserIds: number[];
};
export type GetAvailablePtCountByStationsGroupsResponse = {
  counts: {
    [key: string]: number;
  };
};
export type GetAvailablePtCountByStationsGroupsRequest = {
  stationsGroupsIdsMap?: {
    [key: string]: number[];
  };
};
export type GetAvailablePtCountByStationsResponse = {
  count: number;
};
export type GetShiftByIdResponse = {
  shift: Shift;
};
export type EditShiftResponse = {
  shift: Shift;
};
export type EditShiftRequest = {
  fulfillmentCount: number | null;
  fulltimeCount: number | null;
  shiftTitle: string | null;
  shiftDescription: string | null;
};
export type MakeExpireShiftResponse = {
  shift: Shift;
};
export type BorrowingRequestResponse = {
  shiftRequest: ShiftRequest;
};
export type BorrowingRequestParam = {
  borrowingStatus: BorrowingRequestStatus;
};
export type ListStationResponse = {
  totalCount: number;
  nextPageIndex: number | null;
  results: BriefStation[];
};
export type ListStationsResponse = {
  stations: Station[];
};
export type WorkingStationResponse = {
  station: WorkingStation;
};
export type ListWorkingStationResponse = {
  totalCount: number;
  nextPageIndex: number | null;
  results: WorkingStation[];
};
export type TemplateMessage = {
  id: number;
  slug: string;
  seq: number | null;
  type: "user" | "system";
  isActive: boolean;
  externalId: string;
  locale: string;
  body: string;
  config: object;
};
export type ListTemplateMessageResponse = {
  items: TemplateMessage[];
};
export type TestRequest = {
  sender: string;
  recipient: string;
  template: string;
};
export type IncidentContentTemplate = {
  id: number;
  slug: string;
  seq: number | null;
  isActive: boolean;
  name: LocalizedString;
  title: LocalizedString;
  body: LocalizedString;
};
export type ListIncidentContentTemplateResponse = {
  items: IncidentContentTemplate[];
};
export type BodyMessageReceivedEventHandlerWebhookTwilioMessagePost = {
  SmsMessageSid: string;
  NumMedia: string;
  ProfileName: string;
  SmsSid: string;
  WaId: string;
  SmsStatus: string;
  To: string;
  NumSegments: string;
  ReferralNumMedia: string;
  MessageSid: string;
  AccountSid: string;
  From: string;
  ApiVersion: string;
  Body?: string | null;
  MediaUrl0?: string | null;
  MediaUrl1?: string | null;
  MediaUrl2?: string | null;
  MediaUrl3?: string | null;
  MediaUrl4?: string | null;
  MediaUrl5?: string | null;
  MediaUrl6?: string | null;
  MediaUrl7?: string | null;
  MediaUrl8?: string | null;
  MediaUrl9?: string | null;
  MediaContentType0?: string | null;
  MediaContentType1?: string | null;
  MediaContentType2?: string | null;
  MediaContentType3?: string | null;
  MediaContentType4?: string | null;
  MediaContentType5?: string | null;
  MediaContentType6?: string | null;
  MediaContentType7?: string | null;
  MediaContentType8?: string | null;
  MediaContentType9?: string | null;
  ButtonText?: string | null;
  ButtonPayload?: string | null;
};
export type BodyStatusUpdateEventHandlerWebhookTwilioStatusPost = {
  SmsSid: string;
  SmsStatus: string;
  MessageStatus: string;
  To: string;
  MessageSid: string;
  AccountSid: string;
  From: string;
  ApiVersion: string;
  ChannelToAddress?: string | null;
  ChannelPrefix?: string | null;
  ErrorCode?: string | null;
  ErrorMessage?: string | null;
  ChannelInstallSid?: string | null;
  StructuredMessage?: string | null;
};
export type NotificationType = any;
export type Notification = {
  id: number;
  unread: boolean;
  station: Station;
  shift: Shift;
  ptUser: PtUser;
  type: NotificationType;
  createdAt: string;
  updatedAt: string;
};
export type GetListNotificationsResponse = {
  totalCount: number;
  nextPageIndex: number | null;
  results: Notification[];
  unreadCount: number;
};
export type ReadNotificationsResponse = {
  notification: Notification;
};
export type CreateAssetResponse = {
  asset: Asset;
  uploadUrl: string;
};
export type CreateAssetRequest = {
  filename: string;
  contentSize: number;
  contentType: string;
};
export type GenerateTokenResponse = {
  token: string;
};
export type GenerateWebsocketTokenResponse = {
  token: string;
};
export type UserStationRole = {
  userId: number;
  station: BriefStation;
  role: string;
};
export type UserDetail = {
  id: number;
  adminRole: string;
  name: string;
  nameZhHk: string;
  phoneNumber: string;
  email: string;
  profilePic: Asset | null;
  stationRoles: UserStationRole[];
  selectedProfile?: UserStationProfile | null;
  railwayLine?: RailwayLine | null;
};
export type GetUserResponse = {
  user: UserDetail;
};
export type InviteUserRequest = {
  stationId: number;
};
export type LeaveStationRequest = {
  stationId: number;
};
export type CreateUserResponse = {
  user: User;
};
export type RequestStationRole = {
  stationId: number;
  role: string;
};
export type CreateUserRequest = {
  nameEn: string;
  nameZhHk: string;
  adminRole: UserAdminRole;
  phoneNumber: string;
  email: string;
  lineCode?: string | null;
  stationRoles?: RequestStationRole[] | null;
};
export type ListUserResponse = {
  totalCount: number;
  nextPageIndex: number | null;
  results: User[];
};
export type EditUserResponse = {
  user: User;
};
export type StationRole2 = {
  stationId: number;
  role: string;
};
export type EditUserRequest = {
  stationRoles: StationRole2[];
};
export type UserStationInviteRecord = {
  id: number;
  user: User;
  station: BriefStation;
  isNotified: boolean;
};
export type ListUserStationInviteRecordResponse = {
  items: UserStationInviteRecord[];
};
export type SendMasterShiftInvitationRequest = {
  ptUserIds: number[];
  stationIds: number[];
};
export type GetMasterShiftRequestCountResponse = {
  count: number;
};
export type GetJobAvailablePtUserIdsResponse = {
  ptUserIds: number[];
};
export type MasterShiftDetail = {
  id: number;
  masterShiftId: string;
  shiftTitle: string;
  shiftDescription: string;
  fulfillmentCount: number;
  totalShiftCount: number;
  shiftDatetimeSlots: object;
  ptUserRoles: PtUserRole[] | null;
  workingStation: WorkingStation;
  supportStation: WorkingStation | null;
  rate: ShiftRate | null;
  shiftType: ShiftType | null;
  isIncident: boolean;
  isAppliableToAllAtT3: boolean;
  shifts: Shift[];
};
export type GetMasterShiftResponse = {
  masterShift: MasterShiftDetail;
};
export type MasterShift = {
  id: number;
  masterShiftId: string;
  shiftTitle: string;
  shiftDescription: string;
  fulfillmentCount: number;
  totalShiftCount: number;
  shiftDatetimeSlots: object;
  ptUserRoles: PtUserRole[] | null;
  workingStation: WorkingStation;
  supportStation: WorkingStation | null;
  rate: ShiftRate | null;
  shiftType: ShiftType | null;
  isIncident: boolean;
  isAppliableToAllAtT3: boolean;
};
export type CreateMasterShiftResponse = {
  masterShift: MasterShift;
};
export type ShiftTimeRange = {
  dutyStartTime: string;
  dutyEndTime: string | null;
};
export type CreateMasterShiftRequest = {
  fulfillmentCount: number;
  shiftTitle: string;
  shiftDescription: string;
  ptUserRoleId?: number | null;
  ptUserRoleIds: number[];
  workingStationId: number;
  shiftDatetimeRanges: ShiftTimeRange[];
  shiftDatetimeSlots: object;
  shiftRateId?: number | null;
  shiftType?: ShiftType | null;
  supportStationId?: number | null;
  isAppliableToAllAtT3?: boolean | null;
};
export type ListStationTeamResponse = {
  stationTeams: StationTeam[];
};
export type RailwayLineWithStation = {
  id: number;
  name: LocalizedString;
  shortCode: string;
  colorHexcode: string;
  stations: BriefStation[];
};
export type ListRailwayLineResponse = {
  railwayLines: RailwayLineWithStation[];
};
export type PublicHoliday = {
  id: number;
  name: LocalizedString;
  date: string;
};
export type GetListPublicHolidayResponse = {
  results: PublicHoliday[];
};
export type ListShiftRateResponse = {
  rates: ShiftRate[];
};
export type ListShiftRequestResponse = {
  totalCount: number;
  nextPageIndex: number | null;
  results: ShiftRequest[];
};
export type CreateShiftFullTimeAttendanceResponse = {
  item: ShiftFullTimeAttendance;
};
export type CreateShiftFullTimeAttendanceRequest = {
  nameEn: string;
  nameZhHk: string;
  stationId: number;
  role: string;
};
export type ListShiftFullTimeAttendanceResponse = {
  items: ShiftFullTimeAttendance[];
};
export type ShiftFullTimeAttendanceResponse = {
  item: ShiftFullTimeAttendance;
};
export type GenerateUsageRequest = {
  year: number;
  month: number;
};
export type PtPostShiftApplicationResponse = {
  shiftRequest: ShiftRequest;
};
export type ShiftRateByDate = {
  id: number;
  date: string;
  code: string;
  colorHexcode: string;
};
export type PtGetListShiftRateByDateResponse = {
  rates: ShiftRateByDate[];
};
export type ShiftCompletedViolation = {
  code?: any;
  shiftId: number;
  details?: null;
};
export type ShiftCancelledViolation = {
  code?: any;
  shiftId: number;
  details?: null;
};
export type ShiftExpiredViolation = {
  code?: any;
  shiftId: number;
  details?: null;
};
export type ViolationDetails = {
  conflictShiftStationCode: string;
  conflictShiftTitle: string;
  conflictShiftDutyStartTime: string;
  conflictShiftDutyEndTime: string | null;
};
export type ConflictWithHiredShiftViolation = {
  code?: any;
  shiftId: number;
  details: ViolationDetails;
};
export type ConflictWithRegularScheduleViolation = {
  code?: any;
  shiftId: number;
  details?: null;
};
export type ViolationDetails2 = {
  ptUserScheduledSuspendAt: string;
};
export type PtUserSuspendedViolation = {
  code?: any;
  shiftId: number;
  details: ViolationDetails2;
};
export type ShiftForPtUserView = {
  id: number;
  createdAt: string;
  displayId: string;
  fulfillmentCount: number;
  fulltimeCount: number;
  hiredCount: number;
  dutyStartTime: string;
  dutyEndTime: string | null;
  shiftTitle: string;
  shiftDescription: string;
  ptUserRoles: PtUserRole[];
  rate: ShiftRate | null;
  workingStationId: number;
  workingStation: WorkingStationWithMainStation;
  supportStationId: number | null;
  supportStation: WorkingStationWithMainStation | null;
  status: ShiftStatus;
  completeReason: LocalizedString | null;
  cancelReason: LocalizedString | null;
  masterShiftId: number;
  shiftType: ShiftType;
  incidentAccessControl?: IncidentShiftAccessControl | null;
  isIncident: boolean;
  isAppliableToAllAtT3: boolean;
  fullTimeAttendances?: ShiftFullTimeAttendance[] | null;
  isLiked: boolean;
  isRead: boolean;
  isBookmarked: boolean;
  shiftRequest: ShiftRequestBaseWithNumbering | null;
  violations:
    | (
        | ShiftCompletedViolation
        | ShiftCancelledViolation
        | ShiftExpiredViolation
        | ConflictWithHiredShiftViolation
        | ConflictWithRegularScheduleViolation
        | PtUserSuspendedViolation
      )[]
    | null;
};
export type PtCancelApplyShiftResponse = {
  shift: ShiftForPtUserView;
};
export type GetShiftApplicationResponse = {
  shiftId: number;
};
export type GetUnreadShiftCountResponse = {
  hiredShiftCount: number;
  pendingShiftCount: number;
  newShiftCount: number;
};
export type PtGetListShiftsResponse = {
  totalCount: number;
  nextPageIndex: number | null;
  results: ShiftForPtUserView[];
};
export type GetShiftResponse = {
  shift: ShiftForPtUserView;
};
export type PtGetShiftByIdResponse = {
  shift: ShiftForPtUserView;
};
export type LikeShiftResponse = {
  shift: ShiftForPtUserView;
};
export type UnlikeShiftResponse = {
  shift: ShiftForPtUserView;
};
export type BookmarkShiftResponse = {
  shift: ShiftForPtUserView;
};
export type UnbookmarkShiftResponse = {
  shift: ShiftForPtUserView;
};
export type PtGetShiftRequestByIdResponse = {
  shiftRequest: ShiftRequest | null;
};
export type GetShiftRequestResponse = {
  shiftRequest: ShiftRequest;
};
export type MarkShiftRequestsReadRequest = {
  shiftId: number;
};
export type GetMasterShiftIdResponse = {
  masterShiftId: number;
};
export type BriefShiftRequest = {
  shiftId: number;
  ptUserId: number;
  rejectReason: string | null;
  sentRequestCount: number;
  status: ShiftRequestStatus;
  borrowingRequestStatus: BorrowingRequestStatus | null;
  chatId: number | null;
  createdAt: string;
  updatedAt: string;
  appliedAt: string | null;
  hiredAt: string | null;
  approvedAt: string | null;
  rejectedAt: string | null;
};
export type ApplyMasterShiftResponse = {
  masterShift: MasterShiftDetail;
  ptUserShiftRequests: BriefShiftRequest[];
};
export type ApplyMasterShiftRequest = {
  shiftIds: number[];
};
export type GetMasterShiftApplicationResponse = {
  masterShift: MasterShift;
};
export type ConsentPtUserResponse = {
  ptUser: PtUser;
};
export type RailwayLineWithWorkingStation = {
  id: number;
  name: LocalizedString;
  shortCode: string;
  colorHexcode: string;
  workingStations: WorkingStation[];
};
export type ListRailwayLinesResponse = {
  railwayLines: RailwayLineWithWorkingStation[];
};
export type FrontlineAppConfig = {
  shouldShowCancelApplyFeature: boolean;
  enableCcoRole: boolean;
};
export type PtUserShiftCancelCount = {
  count: number;
  updatedAt: string;
};
export type GetPtUserShiftCancelResponse = {
  ptUserShiftCancel: PtUserShiftCancelCount | null;
};
export type CustomerRailwayLineWithWorkingStation = {
  id: number;
  name: LocalizedString;
  shortCode: string | null;
  colorHexcode: string;
  workingStations: WorkingStation[];
};
export type ListRailwayLinesResponse2 = {
  customerRailwayLines: CustomerRailwayLineWithWorkingStation[];
};
export const {
  useListAdminAppConfigHandlerAdminAppConfigGetQuery,
  useLazyListAdminAppConfigHandlerAdminAppConfigGetQuery,
  useCreateAnnouncementHandlerAnnouncementsPostMutation,
  useListAnnouncementHandlerAnnouncementsListGetQuery,
  useLazyListAnnouncementHandlerAnnouncementsListGetQuery,
  useGetAnnouncementByIdHandlerAnnouncementsAnnouncementIdGetQuery,
  useLazyGetAnnouncementByIdHandlerAnnouncementsAnnouncementIdGetQuery,
  useAuthenticateHandlerAuthenticateGetQuery,
  useLazyAuthenticateHandlerAuthenticateGetQuery,
  useListMessageHandlerChatsChatIdMessagesGetQuery,
  useLazyListMessageHandlerChatsChatIdMessagesGetQuery,
  useGetUnreadChatCountHandlerChatsUnreadChatCountGetQuery,
  useLazyGetUnreadChatCountHandlerChatsUnreadChatCountGetQuery,
  useGetChatHandlerChatsChatIdGetQuery,
  useLazyGetChatHandlerChatsChatIdGetQuery,
  useListChatHandlerChatsGetQuery,
  useLazyListChatHandlerChatsGetQuery,
  useMarkChatReadHandlerChatsChatIdMarkReadPostMutation,
  useSendTemplateMessageHandlerChatsChatIdTemplateMessagePostMutation,
  useSendTextMessageHandlerChatsChatIdTextMessagePostMutation,
  useSyncConversationParticipantHandlerCronSyncConversationParticipantPostMutation,
  useApiHealthCheckHandlerApiHealthCheckGetQuery,
  useLazyApiHealthCheckHandlerApiHealthCheckGetQuery,
  useHealthCheckHandlerHealthCheckGetQuery,
  useLazyHealthCheckHandlerHealthCheckGetQuery,
  useGetMeHandlerMeGetQuery,
  useLazyGetMeHandlerMeGetQuery,
  useUpdateLanguagePreferenceHandlerMeLanguagePreferencePostMutation,
  useSwitchProfileHandlerMeSwitchProfilePostMutation,
  useRevokeTokenHandlerMeRevokeTokenPostMutation,
  useDeletePtUserWorkingStatusRecordHandlerPartTimeUsersPtUserIdWorkingStatusRecordDeleteMutation,
  useUpsertPtUserWorkingStatusRecordHandlerPartTimeUsersPtUserIdWorkingStatusRecordPostMutation,
  useListPtUserWorkingStatusRecordHandlerPartTimeUsersWorkingStatusRecordsPostQuery,
  useLazyListPtUserWorkingStatusRecordHandlerPartTimeUsersWorkingStatusRecordsPostQuery,
  useCreatePtUserHandlerPartTimeUsersPostMutation,
  useCreatePtUserStationHandlerPartTimeUsersStationsPostMutation,
  useCheckEidHandlerPartTimeUsersCheckEidGetQuery,
  useLazyCheckEidHandlerPartTimeUsersCheckEidGetQuery,
  useDeletePtUserHandlerPartTimeUsersPtUserIdDeleteMutation,
  useGetPtUserByIdHandlerPartTimeUsersPtUserIdGetQuery,
  useLazyGetPtUserByIdHandlerPartTimeUsersPtUserIdGetQuery,
  useUpdatePtUserHandlerPartTimeUsersPtUserIdPostMutation,
  useGetPtUsersRolesHandlerPartTimeUsersRolesGetQuery,
  useLazyGetPtUsersRolesHandlerPartTimeUsersRolesGetQuery,
  useGetCountByStationGroupsHandlerPartTimeUsersGetCountByStationGroupsGetQuery,
  useLazyGetCountByStationGroupsHandlerPartTimeUsersGetCountByStationGroupsGetQuery,
  useGetCountByStationsHandlerPartTimeUsersGetPtCountByStationsPostQuery,
  useLazyGetCountByStationsHandlerPartTimeUsersGetPtCountByStationsPostQuery,
  useGetTotalCountByStationsHandlerPartTimeUsersGetTotalPtCountByStationsGetQuery,
  useLazyGetTotalCountByStationsHandlerPartTimeUsersGetTotalPtCountByStationsGetQuery,
  useListByStationsHandlerPartTimeUsersListByStationsGetQuery,
  useLazyListByStationsHandlerPartTimeUsersListByStationsGetQuery,
  useBatchSendWelcomeMessageHandlerPartTimeUsersBatchSendWelcomeMessagePostMutation,
  useGetPtUsersHandlerPartTimeUsersListPostQuery,
  useLazyGetPtUsersHandlerPartTimeUsersListPostQuery,
  useReactivatePtUserHandlerPartTimeUsersPtUserIdReactivatePostMutation,
  useWelcomePtUserHandlerPartTimeUsersPtUserIdWelcomePostMutation,
  useGetPtUserShiftsHandlerPartTimeUsersPtUserIdShiftsGetQuery,
  useLazyGetPtUserShiftsHandlerPartTimeUsersPtUserIdShiftsGetQuery,
  useSchedulePtUserSuspensionHandlerPartTimeUsersPtUserIdScheduleSuspensionPostMutation,
  useListPtUserShiftCancelByShiftHandlerPtUserShiftCancelsShiftIdGetQuery,
  useLazyListPtUserShiftCancelByShiftHandlerPtUserShiftCancelsShiftIdGetQuery,
  useListShiftCompleteReasonsHandlerShiftCompleteReasonsGetQuery,
  useLazyListShiftCompleteReasonsHandlerShiftCompleteReasonsGetQuery,
  useExportAllShiftsHandlerShiftsExportAllPostMutation,
  useListShiftCancelReasonsHandlerShiftsCancelReasonsGetQuery,
  useLazyListShiftCancelReasonsHandlerShiftsCancelReasonsGetQuery,
  useListBorrowingRequestsHandlerShiftsBorrowingShiftRequestsGetQuery,
  useLazyListBorrowingRequestsHandlerShiftsBorrowingShiftRequestsGetQuery,
  useHireHandlerShiftsShiftIdHirePostMutation,
  useCancelHireHandlerShiftsShiftIdCancelHirePtUserPtUserIdPostMutation,
  useContactHandlerShiftsShiftIdContactedPtUserPtUserIdPostMutation,
  useGetChatHandlerShiftsShiftIdChatWithPtuserPtUserIdGetQuery,
  useLazyGetChatHandlerShiftsShiftIdChatWithPtuserPtUserIdGetQuery,
  useCreateShiftHandlerShiftsPostMutation,
  useListShiftsHandlerShiftsGetQuery,
  useLazyListShiftsHandlerShiftsGetQuery,
  useBatchCancelShiftsHandlerShiftsBatchCancelPostMutation,
  useSendShiftRequestsHandlerShiftsShiftIdRequestsPostMutation,
  useListShiftRequestsWithNumberingHandlerShiftsShiftIdRequestsGetQuery,
  useLazyListShiftRequestsWithNumberingHandlerShiftsShiftIdRequestsGetQuery,
  useGetAvailablePtUserHandlerShiftsAvailablePtUsersGetQuery,
  useLazyGetAvailablePtUserHandlerShiftsAvailablePtUsersGetQuery,
  useGetAvailablePtCountHandlerShiftsAvailablePtCountGetQuery,
  useLazyGetAvailablePtCountHandlerShiftsAvailablePtCountGetQuery,
  useGetAvailablePtUserByStationsHandlerShiftsAvailablePtUsersByStationsGetQuery,
  useLazyGetAvailablePtUserByStationsHandlerShiftsAvailablePtUsersByStationsGetQuery,
  useGetAvailablePtCountByStationsGroupsHandlerShiftsAvailablePtCountByStationsPostQuery,
  useLazyGetAvailablePtCountByStationsGroupsHandlerShiftsAvailablePtCountByStationsPostQuery,
  useGetAvailablePtCountByStationsHandlerShiftsAvailablePtCountByStationsGetQuery,
  useLazyGetAvailablePtCountByStationsHandlerShiftsAvailablePtCountByStationsGetQuery,
  useGetShiftByIdHandlerShiftsShiftIdGetQuery,
  useLazyGetShiftByIdHandlerShiftsShiftIdGetQuery,
  useEditShiftHandlerShiftsShiftIdPostMutation,
  useMakeExpireShiftHandlerShiftsShiftIdMakeExpirePostMutation,
  useBorrowingRequestHandlerShiftsRequestsShiftIdPtUserIdBorrowingPostMutation,
  useListStationHandlerStationsGetQuery,
  useLazyListStationHandlerStationsGetQuery,
  useListByShiftHandlerStationsListByShiftShiftIdGetQuery,
  useLazyListByShiftHandlerStationsListByShiftShiftIdGetQuery,
  useGetWorkingStationHandlerWorkingStationsIdGetQuery,
  useLazyGetWorkingStationHandlerWorkingStationsIdGetQuery,
  useListWorkingStationHandlerWorkingStationsGetQuery,
  useLazyListWorkingStationHandlerWorkingStationsGetQuery,
  useListTemplateMessageHandlerTemplateMessageGetQuery,
  useLazyListTemplateMessageHandlerTemplateMessageGetQuery,
  useTestSendHandlerTemplateMessageTestSendPostMutation,
  useListIncidentContentTemplateHandlerIncidentContentTemplatesGetQuery,
  useLazyListIncidentContentTemplateHandlerIncidentContentTemplatesGetQuery,
  useGenerateTwilioTokenHandlerTwilioTokenPostMutation,
  useMessageReceivedEventHandlerWebhookTwilioMessagePostMutation,
  useStatusUpdateEventHandlerWebhookTwilioStatusPostMutation,
  useListNotificationsHandlerNotificationsGetQuery,
  useLazyListNotificationsHandlerNotificationsGetQuery,
  useReadNotificationHandlerNotificationsNotificationIdReadPostMutation,
  useCreateAssetHandlerAssetsPostMutation,
  useGenerateTokenHandlerTokenGetQuery,
  useLazyGenerateTokenHandlerTokenGetQuery,
  useGenerateWebsocketTokenHandlerTokenWebsocketGetQuery,
  useLazyGenerateWebsocketTokenHandlerTokenWebsocketGetQuery,
  useValidateInviteHandlerUsersValidateInviteGetQuery,
  useLazyValidateInviteHandlerUsersValidateInviteGetQuery,
  useInviteManagerToStationHandlerUsersInviteUserIdPostMutation,
  useLeaveStationHandlerUsersLeaveStationPostMutation,
  useCreateUserHandlerUsersPostMutation,
  useListUserHandlerUsersGetQuery,
  useLazyListUserHandlerUsersGetQuery,
  useEditUserHandlerUsersUserIdPostMutation,
  useGetUserHandlerUsersUserIdGetQuery,
  useLazyGetUserHandlerUsersUserIdGetQuery,
  useListUserStationInviteRecordHandlerUserStationInviteRecordsGetQuery,
  useLazyListUserStationInviteRecordHandlerUserStationInviteRecordsGetQuery,
  useReadUserStationInviteRecordHandlerUserStationInviteRecordsReadPostMutation,
  useSendMasterShiftInvitationHandlerMasterShiftsMasterShiftIdInvitePostMutation,
  useGetMasterShiftRequestCountHandlerMasterShiftsMasterShiftIdRequestCountGetQuery,
  useLazyGetMasterShiftRequestCountHandlerMasterShiftsMasterShiftIdRequestCountGetQuery,
  useGetCountByStationGroupsHandlerMasterShiftsGetCountByStationGroupsGetQuery,
  useLazyGetCountByStationGroupsHandlerMasterShiftsGetCountByStationGroupsGetQuery,
  useGetAvailablePtUserByStationsHandlerMasterShiftsAvailablePtUsersByStationsGetQuery,
  useLazyGetAvailablePtUserByStationsHandlerMasterShiftsAvailablePtUsersByStationsGetQuery,
  useGetMasterShiftHandlerMasterShiftsMasterShiftIdGetQuery,
  useLazyGetMasterShiftHandlerMasterShiftsMasterShiftIdGetQuery,
  useCreateMasterShiftHandlerMasterShiftsPostMutation,
  useListStationTeamHandlerStationTeamsGetQuery,
  useLazyListStationTeamHandlerStationTeamsGetQuery,
  useListRailwayLineHandlerRailwayLinesGetQuery,
  useLazyListRailwayLineHandlerRailwayLinesGetQuery,
  useListPublicHolidaysHandlerPublicHolidaysGetQuery,
  useLazyListPublicHolidaysHandlerPublicHolidaysGetQuery,
  useListShiftRateHandlerShiftRatesGetQuery,
  useLazyListShiftRateHandlerShiftRatesGetQuery,
  useListShiftRequestHandlerShiftRequestsGetQuery,
  useLazyListShiftRequestHandlerShiftRequestsGetQuery,
  useExportShiftRequestHandlerShiftRequestsExportPostMutation,
  useAddShiftFullTimeAttendanceHandlerShiftFullTimeAttendancesPostMutation,
  useListShiftFullTimeAttendanceByShiftHandlerShiftFullTimeAttendancesGetQuery,
  useLazyListShiftFullTimeAttendanceByShiftHandlerShiftFullTimeAttendancesGetQuery,
  useRemoveShiftFullTimeAttendanceHandlerShiftFullTimeAttendancesAttendanceIdDeleteMutation,
  useExportUsageHandlerWhatsappUsageExportPostMutation,
  useApplyShiftHandlerPtShiftsShiftIdApplyPostMutation,
  usePtUserListShiftsRateByDateHandlerPtShiftsListShiftRateByDateGetQuery,
  useLazyPtUserListShiftsRateByDateHandlerPtShiftsListShiftRateByDateGetQuery,
  useCancelApplyShiftHandlerPtShiftsShiftIdCancelApplyPostMutation,
  useGetShiftApplicationHandlerPtShiftsApplicationGetQuery,
  useLazyGetShiftApplicationHandlerPtShiftsApplicationGetQuery,
  useGetUnreadShiftCountHandlerPtShiftsPtUnreadShiftCountGetQuery,
  useLazyGetUnreadShiftCountHandlerPtShiftsPtUnreadShiftCountGetQuery,
  usePtUserListShiftsHandlerPtShiftsListGetQuery,
  useLazyPtUserListShiftsHandlerPtShiftsListGetQuery,
  useMarkShiftReadHandlerPtShiftsShiftIdMarkReadPostMutation,
  useGetShiftByIdHandlerPtShiftsShiftIdGetQuery,
  useLazyGetShiftByIdHandlerPtShiftsShiftIdGetQuery,
  useLikeShiftHandlerPtShiftsShiftIdLikePostMutation,
  useUnlikeShiftHandlerPtShiftsShiftIdUnlikePostMutation,
  useBookmarkShiftHandlerPtShiftsShiftIdBookmarkPostMutation,
  useUnbookmarkShiftHandlerPtShiftsShiftIdUnbookmarkPostMutation,
  useGetShiftRequestByIdHandlerPtShiftRequestsShiftsShiftIdGetQuery,
  useLazyGetShiftRequestByIdHandlerPtShiftRequestsShiftsShiftIdGetQuery,
  useMarkShiftRequestReadHandlerPtShiftRequestsMarkReadPostMutation,
  usePtUserAuthenticateHandlerPtPtUserAuthenticateGetQuery,
  useLazyPtUserAuthenticateHandlerPtPtUserAuthenticateGetQuery,
  useGetMasterShiftIdFromApplicationHandlerPtMasterShiftsIdFromApplicationGetQuery,
  useLazyGetMasterShiftIdFromApplicationHandlerPtMasterShiftsIdFromApplicationGetQuery,
  useApplyMasterShiftByIdHandlerPtMasterShiftsJobIdApplyPostMutation,
  useGetMasterShiftDetailHandlerPtMasterShiftsJobIdGetQuery,
  useLazyGetMasterShiftDetailHandlerPtMasterShiftsJobIdGetQuery,
  useConsentPtUserHandlerPtPartTimeUsersPtUserIdConsentPostMutation,
  useListRailwayLinesHandlerPtRailwayLinesGetQuery,
  useLazyListRailwayLinesHandlerPtRailwayLinesGetQuery,
  useListFrontlineAppConfigHandlerPtFrontlineAppConfigGetQuery,
  useLazyListFrontlineAppConfigHandlerPtFrontlineAppConfigGetQuery,
  useGetPtUserShiftCancelHandlerPtPtUserShiftCancelShiftIdGetQuery,
  useLazyGetPtUserShiftCancelHandlerPtPtUserShiftCancelShiftIdGetQuery,
  useListCustomerRailwayLinesHandlerPtCustomerRailwayLinesGetQuery,
  useLazyListCustomerRailwayLinesHandlerPtCustomerRailwayLinesGetQuery,
} = injectedRtkApi;
