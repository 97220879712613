import React, {
  ReactElement,
  useEffect,
  useId,
  useMemo,
  useState,
  useCallback,
  useContext,
} from "react";
import { TFunction } from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import cn from "classnames";

import { Button, ButtonLink } from "oneclick-component/src/components/Button";
import { ToggleSwitch } from "oneclick-component/src/components/ToggleSwitch";
import usePromptPageLeave from "oneclick-component/src/hooks/usePromptPageLeave";
import AppRoutes from "../../routes/AppRoutes";
import {
  useGetPtUsersRolesHandlerPartTimeUsersRolesGetQuery,
  useListShiftRateHandlerShiftRatesGetQuery,
  WorkingStation,
} from "oneclick-component/src/store/apis/enhancedApi";
import {
  dateTimeNow,
  isoToDateTime,
  getGroupedWeekdays,
} from "oneclick-component/src/utils/datetime";
import { localizeString } from "oneclick-component/src/utils/localize";
import MultiShiftForm from "../../components/MultiShiftForm";
import {
  MultiShiftFormValues,
  multiShiftFormSchema,
} from "../../components/MultiShiftForm/form";
import { Option } from "oneclick-component/src/components/inputs";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import { makeShiftDisplayInterval } from "oneclick-component/src/utils/shift";
import { useSelectedStationProfile } from "../../hooks/useSelectedStationProfile";
import { save, clear } from "../../store/multiShiftCreateForm";
import {
  updatePreview,
  MultiShiftCreateFormPreviewType,
} from "../../store/multiShiftPreview";
import StepBar from "../../components/StepBar";
import { RootState } from "../../store/store";
import {
  DEFAULT_SHIFT_START_TIME_HOUR,
  DEFAULT_SHIFT_START_TIME_MINUTE,
} from "../../constants/shifts/create";
import { showErrorScreen } from "../../utils/error";
import useShiftRateOptions from "../../hooks/useShiftRateOptions";
import { FeatureConfigContext } from "../../providers/FeatureConfigProvider";

const weekdayTranslation = [
  "weekday.name.mon",
  "weekday.name.tue",
  "weekday.name.wed",
  "weekday.name.thu",
  "weekday.name.fri",
  "weekday.name.sat",
  "weekday.name.sun",
];

const constructDateTimePreview = (
  form: MultiShiftFormValues,
  t: TFunction
): {
  dateString: string;
  timeString: string;
  // eslint-disable-next-line sonarjs/cognitive-complexity
} => {
  let orderedDates = [];
  if (form.recurringOptions.recurringType === "single") {
    orderedDates = [form.recurringOptions.date];
  } else if (form.recurringOptions.recurringType === "repeat") {
    orderedDates = [
      form.recurringOptions.startDate,
      form.recurringOptions.endDate,
    ];
  } else {
    orderedDates = [...form.recurringOptions.dates];
    orderedDates.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );
    orderedDates = orderedDates.map((d) => d.date);
  }

  let datesString = "";
  if (form.recurringOptions.recurringType === "single") {
    const date = isoToDateTime(orderedDates[0]);
    const dateString = makeShiftDisplayInterval(date.toISO()!, null);
    datesString = dateString[1].dateWithWeekDay;
  } else if (form.recurringOptions.recurringType === "repeat") {
    const start = isoToDateTime(form.recurringOptions.startDate);
    const end = isoToDateTime(form.recurringOptions.endDate);
    const startDateString = makeShiftDisplayInterval(start.toISO()!, null);
    const endDateString = makeShiftDisplayInterval(end.toISO()!, null);
    const weekdayGroup = getGroupedWeekdays(form.recurringOptions.weekdays);
    const weekdayGroupDisplay = weekdayGroup.map((wg) => {
      if (wg.length === 2) {
        return `${t(weekdayTranslation[wg[0]])} ${t("weekday.range.to")} ${t(
          weekdayTranslation[wg[1]]
        )}`;
      }
      return t(weekdayTranslation[wg[0]]);
    });
    datesString = t("shift.create.preview.shiftDate.repeat.title", {
      startDate: startDateString[1].dateWithWeekDay,
      endDate: endDateString[1].dateWithWeekDay,
      weekdays: `${t("weekday.name")}${weekdayGroupDisplay.join("，")}`,
    });
  } else {
    const dates = orderedDates.map((d) => {
      const date = isoToDateTime(d);
      const dateString = makeShiftDisplayInterval(date.toISO()!, null);
      return dateString[1].dateWithWeekDay;
    });
    datesString = dates.join("，");
  }

  const timeRanges = [];
  const startDate = orderedDates[0];
  const sortedTimeRanges = [...form.timeRanges];
  sortedTimeRanges.sort(
    (a, b) =>
      a.startTime.hour - b.startTime.hour ||
      a.startTime.minute - b.startTime.minute
  );
  for (const timeRange of sortedTimeRanges) {
    let startDateTime = isoToDateTime(startDate);
    let endDateTime = null;

    startDateTime = startDateTime.set(timeRange.startTime);
    if (timeRange.endTime.hour != null && timeRange.endTime.minute != null) {
      endDateTime = isoToDateTime(startDate);
      endDateTime = endDateTime.set({
        hour: timeRange.endTime.hour,
        minute: timeRange.endTime.minute,
      });
    }

    const dateString = makeShiftDisplayInterval(
      startDateTime.toISO()!,
      endDateTime?.toISO() ?? null
    );

    const timeDisplay =
      dateString[1].endTime !== ""
        ? `${dateString[1].startTime} - ${dateString[1].endTime} ${dateString[1].overnight}`
        : `${dateString[1].startTime} - ${t("common.tbc")}`;
    timeRanges.push(timeDisplay);
  }

  return {
    dateString: datesString,
    timeString: timeRanges.join(" | "),
  };
};

// eslint-disable-next-line complexity
const CreateMultiShiftScreen = (): ReactElement => {
  const { shouldShowIncidentFeature } = useContext(FeatureConfigContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedStationProfile = useSelectedStationProfile();
  const filledForm = useSelector((state: RootState) => {
    return state.multiShiftCreateForm;
  });
  const shiftPreview = useSelector((state: RootState) => {
    return state.multiShiftCreateFormPreview;
  });

  const defaultWorkingStation = useMemo(() => {
    return selectedStationProfile != null
      ? selectedStationProfile.station.workingStations[0] ?? null
      : null;
  }, [selectedStationProfile]);
  const {
    data: ptUserRoles,
    isLoading: isPtUserRolesLoading,
    error: getPtUserRolesError,
  } = useGetPtUsersRolesHandlerPartTimeUsersRolesGetQuery();
  const {
    data: shiftRates,
    isLoading: isShiftRatesLoading,
    error: getShiftRatesError,
  } = useListShiftRateHandlerShiftRatesGetQuery();

  const [selectedWorkingStation, setSelectedWorkingStation] =
    useState<WorkingStation | null>(null);

  const [selectedSupportStation, setSelectedSupportStation] =
    useState<WorkingStation | null>(null);

  const shiftRateOptions: Option<number>[] = useShiftRateOptions();
  const now = useMemo(() => {
    return dateTimeNow();
  }, []);

  const form = useForm<MultiShiftFormValues>({
    resolver: zodResolver(multiShiftFormSchema),
    defaultValues: {
      stationId: defaultWorkingStation?.id,
      supportStationId: null,
      rate: shiftRateOptions.length > 0 ? shiftRateOptions[0].value : undefined,
      ccoRateId: undefined,
      shiftType: "REGULAR",
      ptType: [],
      recurringOptions: {
        recurringType: "single",
        date: now.toFormat("yyyy-LL-dd"),
      },
      fulfillmentCount: "" as any as number,
      timeRanges: [
        {
          startTime: {
            hour: DEFAULT_SHIFT_START_TIME_HOUR,
            minute: DEFAULT_SHIFT_START_TIME_MINUTE,
          },
          endTime: {
            hour: null,
            minute: null,
          },
        },
      ],
      title: "",
      description: "",
      isAppliableToAllAtT3: false,
    },
  });

  const resetForm = useCallback(() => {
    dispatch(clear());
  }, [dispatch]);

  const { handleSubmit, watch, setValue, reset } = form;
  const formValues = watch();

  const nonBlockNavigate = usePromptPageLeave(
    formValues.stationId > 0,
    resetForm
  );

  useEffect(() => {
    if (defaultWorkingStation != null) {
      setValue("stationId", defaultWorkingStation.id);
    }
    if (shiftRateOptions.length > 0) {
      setValue(
        "ccoRateId",
        shiftRateOptions.find((opt) => opt.name.includes("CCO"))?.value
      );
    }
  }, [defaultWorkingStation, setValue, shiftRateOptions]);
  const toggleIncidentCreate = useCallback(() => {
    if (formValues.shiftType === "INCIDENT") {
      setValue("shiftType", "REGULAR");
      setValue("supportStationId", null);
      setValue("title", "");
      setValue("description", "");
      setSelectedWorkingStation(defaultWorkingStation);
    } else {
      // Switch form value to incident shift default values
      const next15Minute = now.plus({ minute: 15 - (now.minute % 15) });
      setValue("shiftType", "INCIDENT");
      setValue("fulfillmentCount", 10);
      setValue("rate", shiftRateOptions[0].value);
      setValue("recurringOptions.recurringType", "single");
      setValue("recurringOptions.date", now.toFormat("yyyy-LL-dd"));
      setValue("ptType", []);
      setValue("timeRanges", []);
      setValue("timeRanges.0.startTime.hour", next15Minute.hour);
      setValue("timeRanges.0.startTime.minute", next15Minute.minute);
      if (defaultWorkingStation != null) {
        setValue("stationId", defaultWorkingStation.id);
        setValue("supportStationId", defaultWorkingStation.id);
        setSelectedSupportStation(defaultWorkingStation);
      }
    }
  }, [
    formValues.shiftType,
    setValue,
    defaultWorkingStation,
    now,
    shiftRateOptions,
  ]);

  const formId = useId();

  const partTimeGroupOptions: Option<string>[] = useMemo(() => {
    if (ptUserRoles == null) {
      return [];
    }

    // Handle special pt user role group
    // `SAS`: Hidden, `SA` will include `SAS`
    // `SA and CUCA`: include `SA`, `SAS`, `CUCA`
    const ptRoleGroupOptions = ptUserRoles.ptUserRoles
      .filter((role) => role.name !== "SAS")
      .map((role) => ({
        name: role.name,
        value: role.name,
      }));

    return ptRoleGroupOptions;
  }, [ptUserRoles]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const onSubmit = useMemo(() => {
    return handleSubmit((form, e?: React.BaseSyntheticEvent) => {
      e?.preventDefault();

      let targetWorkingStation = "";
      if (selectedWorkingStation == null && defaultWorkingStation != null) {
        targetWorkingStation = localizeString(defaultWorkingStation.name);
      } else if (selectedWorkingStation != null) {
        targetWorkingStation = localizeString(selectedWorkingStation.name);
      }

      const previousStatePreview =
        shiftPreview.workingStation === ""
          ? targetWorkingStation
          : shiftPreview.workingStation;
      const preview = constructDateTimePreview(form, t);
      const newPreview: MultiShiftCreateFormPreviewType = {
        workingStation:
          selectedWorkingStation == null
            ? previousStatePreview
            : targetWorkingStation,
        supportStation:
          form.shiftType === "INCIDENT" && selectedSupportStation != null
            ? localizeString(selectedSupportStation.name)
            : undefined,
        ptType: form.ptType,
        rate:
          shiftRates?.rates.find((rate) => rate.id === form.rate) ?? undefined,
        recurringType: form.recurringOptions.recurringType,
        dateDisplay: preview.dateString,
        timeDisplay: preview.timeString,
        fulfillmentCount: form.fulfillmentCount,
        title: form.title,
        description: form.description,
        shiftType: form.shiftType,
        isAppliableToAllAtT3: form.isAppliableToAllAtT3,
      };

      dispatch(save(form));
      dispatch(updatePreview(newPreview));
      nonBlockNavigate(AppRoutes.CreateMultiShiftPreviewScreen.path);
    });
  }, [
    handleSubmit,
    selectedWorkingStation,
    defaultWorkingStation,
    shiftPreview.workingStation,
    t,
    selectedSupportStation,
    shiftRates?.rates,
    dispatch,
    nonBlockNavigate,
  ]);

  useEffect(() => {
    if (filledForm.filled) {
      reset(filledForm);
    }
  }, [filledForm, reset]);

  if (getPtUserRolesError != null || getShiftRatesError != null) {
    return showErrorScreen(getPtUserRolesError ?? getShiftRatesError);
  }

  if (
    isPtUserRolesLoading ||
    isShiftRatesLoading ||
    ptUserRoles == null ||
    shiftRates == null
  ) {
    return (
      <div
        className={cn(
          "fixed",
          "top-1/2",
          "left-1/2",
          "-translate-x-1/2",
          "-translate-y-1/2"
        )}
      >
        <LoadingSpinner size="l" />
      </div>
    );
  }

  return (
    <main className={"pb-20"}>
      <div>
        <StepBar
          className="max-w-160"
          displays={[
            t("shift.create.multiple.step1"),
            t("shift.create.multiple.step2"),
            t("shift.create.multiple.step3"),
          ]}
          step={0}
        />
      </div>
      <div
        className={cn(
          "rounded-lg",
          "bg-white",
          "shadow",
          "max-w-169",
          "mx-auto"
        )}
      >
        {formValues.shiftType === "INCIDENT" ? (
          <div className={cn("bg-red-500", "py-1", "pl-6", "rounded-t-lg")}>
            <p className={cn("text-white", "font-medium", "text-sm")}>
              <Trans i18nKey="shiftDetail.header.incidentBanner" />
            </p>
          </div>
        ) : null}
        <div className="p-6">
          <section>
            <div className={cn("flex", "flex-row")}>
              <p className={cn("font-medium", "text-lg", "grow", "mb-10")}>
                <Trans i18nKey="shift.create.title" />
              </p>
              {shouldShowIncidentFeature ? (
                <ToggleSwitch
                  label={t("shiftDetail.header.incidentBanner")}
                  className={cn("text-red-600", "text-sm", "font-normal")}
                  inputClassName={cn(
                    "checked:bg-red-500",
                    "hover:checked:bg-red-500",
                    "focus:ring-red-500",
                    "focus:checked:bg-red-500",
                    "h-6",
                    "!w-11",
                    "after:ml-0.5",
                    "after:mt-0.5",
                    "checked:after:mt-0.5",
                    "checked:after:ml-5.5",
                    "focus:!ring-offset-4"
                  )}
                  isChecked={formValues.shiftType === "INCIDENT"}
                  onToggle={toggleIncidentCreate}
                />
              ) : null}
            </div>
            <MultiShiftForm
              id={formId}
              form={form}
              onWorkingStationSelected={setSelectedWorkingStation}
              onSupportStationSelected={setSelectedSupportStation}
              partTimeGroupOptions={partTimeGroupOptions}
              shiftRateOptions={shiftRateOptions}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onSubmit={onSubmit}
              isEditing={false}
            />
          </section>
        </div>
      </div>
      <footer
        className={cn(
          "fixed",
          "bottom-0",
          "left-0",
          "bg-white",
          "w-full",
          "h-16",
          "flex",
          "justify-end",
          "px-6",
          "py-3"
        )}
      >
        <ButtonLink
          theme={"white"}
          className={"mr-3 text-sm"}
          to={AppRoutes.ShiftListScreen.render()}
        >
          <Trans i18nKey="shift.create.footer.button.cancel" />
        </ButtonLink>
        <Button type="submit" form={formId}>
          <Trans i18nKey="shift.create.footer.button.next" />
        </Button>
      </footer>
    </main>
  );
};

export default CreateMultiShiftScreen;
