import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import cn from "classnames";
import { Button } from "oneclick-component/src/components/Button";
import Slider from "oneclick-component/src/components/Slider";
import { Option, Select } from "oneclick-component/src/components/inputs";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";
import { SendIcon } from "oneclick-component/src/icon";
import {
  GetAvailablePtUserByStationsHandlerShiftsAvailablePtUsersByStationsGetApiArg as GetAvailablePtUserArg,
  Shift,
  ShiftStationFilterType,
  Weekly418Status,
  useSendShiftRequestsHandlerShiftsShiftIdRequestsPostMutation as useBatchSend,
  useGetAvailablePtUserByStationsHandlerShiftsAvailablePtUsersByStationsGetQuery as useGetAvailableUsers,
  useListByShiftHandlerStationsListByShiftShiftIdGetQuery as useListStations,
} from "oneclick-component/src/store/apis/enhancedApi";
import {
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Trans, useTranslation } from "react-i18next";
import PTUser418Badge from "../../components/PTUserBadge/PTUser418Badge";
import { ConfirmSendAllStationsDialog } from "../../components/ConfirmSendAllStationsDialog";
import { FeatureConfigContext } from "../../providers/FeatureConfigProvider";
import { useShowError } from "../../hooks/useShowError";
import { useSelectedStationProfile } from "../../hooks/useSelectedStationProfile";
import useMeUser from "../../hooks/useMeUser";
import FindPTUserStationSelect, {
  LineStations,
} from "./FindPTUserStationSelect";

interface SendInvitationButtonProps {
  pendingUserIds: number[];
  handleSendAll: () => void;
  isBatchSendLoading: boolean;
  isPartTimeListLoading: boolean;
}

const SendInvitationButton = (props: SendInvitationButtonProps) => {
  const {
    pendingUserIds,
    handleSendAll,
    isBatchSendLoading,
    isPartTimeListLoading,
  } = props;

  return (
    <Button
      className={cn(
        "flex-1",
        "min-h-10.5",
        "sm:mt-0",
        "self-end",
        "lg:flex-none"
      )}
      prefixIcon={SendIcon}
      theme="primary"
      onClick={handleSendAll}
      disabled={
        pendingUserIds.length === 0 ||
        isBatchSendLoading ||
        isPartTimeListLoading
      }
      isLoading={isBatchSendLoading || isPartTimeListLoading}
    >
      <span className={cn("mx-auto", "sm:mx-0")}>
        <Trans
          i18nKey={
            pendingUserIds.length === 0
              ? "shiftDetail.action.sendAll.noSelection"
              : "shiftDetail.action.sendAll"
          }
        />
      </span>
    </Button>
  );
};

interface Props {
  shift: Shift;
  defaultExpand: boolean;
}

const filterType: Array<ShiftStationFilterType> = [
  "home",
  "relative",
  "line",
  "all",
];

const FindPTUserSection = (props: Props): ReactElement => {
  const { shift, defaultExpand } = props;

  const { t } = useTranslation();
  const { showError } = useShowError();
  const showMessage = useShowMessage();
  const meUserSelectedStation = useSelectedStationProfile();
  const meUser = useMeUser();
  const [showPtUserList, setShowPtUserList] = useState(defaultExpand);
  const [stationGroupIndex, setStationGroupIndex] = useState(0);
  const [isLoadAllStations, setIsLoadAllStations] = useState(false);
  const [sendConfirmDialog, setSendConfirmDialog] = useState(false);
  const { shouldShowGreen418Badge, allStationsFilterEnabledStations } =
    useContext(FeatureConfigContext);

  const enableAllStations = useMemo(() => {
    if (meUser?.adminRole === "ADMIN") {
      return true;
    }
    if (meUserSelectedStation?.station.shortCode == null) {
      return false;
    }
    return (
      meUserSelectedStation.station.shortCode !== "" &&
      allStationsFilterEnabledStations.includes(
        meUserSelectedStation.station.shortCode
      )
    );
  }, [meUserSelectedStation, allStationsFilterEnabledStations, meUser]);

  const [status418Filter, setStatus418Filter] =
    useState<Weekly418Status | null>(null);

  const [selectedStationIds, setSelectedStationIds] = useState<number[]>([]);

  const { data: stationsResult, isLoading: isStationLoading } = useListStations(
    {
      shiftId: shift.id,
      filterType: filterType[stationGroupIndex],
    },
    {
      skip:
        stationGroupIndex === 0 ||
        (stationGroupIndex === 3 && (!enableAllStations || !isLoadAllStations)),
    }
  );

  const groupedStations: LineStations[] = useMemo(() => {
    if (stationsResult?.stations == null) {
      return [];
    }
    const lineMap: Record<number, LineStations | undefined> = {};
    let orderedStations = [...stationsResult.stations];
    orderedStations = orderedStations.sort((a, b) => {
      return a.shortCode > b.shortCode ? 1 : -1;
    });

    for (const station of orderedStations) {
      if (station.lines.length === 0) {
        continue;
      }
      const line = station.lines[0];
      if (lineMap[line.id] == null) {
        lineMap[line.id] = {
          line: line,
          stations: [station],
        };
      } else {
        lineMap[line.id]?.stations.push(station);
      }
    }
    return Object.values(lineMap) as LineStations[];
  }, [stationsResult]);

  const defaultSelectedStationIds = useMemo(() => {
    if (stationGroupIndex === 0) {
      setSelectedStationIds([shift.workingStation.mainStationId]);
      return [shift.workingStation.mainStationId];
    }
    if (stationsResult?.stations == null) {
      return [];
    }
    const stationIds = stationsResult.stations.map((st) => st.id);
    return stationIds;
  }, [stationsResult, stationGroupIndex, shift]);

  const requestParams = useMemo<GetAvailablePtUserArg>(() => {
    return {
      showAll: true,
      shiftId: shift.id,
      stationIds: selectedStationIds,
      status418: status418Filter,
    };
  }, [shift, selectedStationIds, status418Filter]);

  const { data: partTimeList, isFetching: isPartTimeListLoading } =
    useGetAvailableUsers(requestParams, {
      refetchOnMountOrArgChange: true,
    });

  const [sendBatch, { isLoading: isBatchSendLoading }] = useBatchSend();
  const [sentPtUserIds, setSentPtUserIds] = useState<number[]>([]);

  const status418FilterOptions: Option<Weekly418Status | null>[] =
    useMemo(() => {
      const nullOption: Option<null> = {
        name: t("common.showAll"),
        value: null,
      };
      const nonGreenStatuses: Weekly418Status[] = ["WARNING", "UNKNOWN"];
      const allStatuses: Weekly418Status[] = shouldShowGreen418Badge
        ? [...nonGreenStatuses, "SAFE"]
        : nonGreenStatuses;
      return [
        nullOption,
        ...allStatuses.map((s) => ({
          name: s,
          value: s,
          displayComponent: <PTUser418Badge status={s} />,
        })),
      ];
    }, [t, shouldShowGreen418Badge]);

  const handleHidePtUserList = useCallback(() => {
    setShowPtUserList(false);
  }, []);

  const handleShowPtUserList = useCallback(() => {
    setShowPtUserList(true);
  }, []);

  const userIds = useMemo(() => {
    if (partTimeList?.ptUserIds == null) {
      return [];
    }
    return partTimeList.ptUserIds;
  }, [partTimeList]);

  const pendingUserIds = useMemo(() => {
    return userIds.filter((u) => !sentPtUserIds.includes(u));
  }, [userIds, sentPtUserIds]);

  const slideLabels = useMemo(() => {
    return [
      t("shiftDetail.stationGroup.home"),
      t("shiftDetail.stationGroup.group"),
      t("shiftDetail.stationGroup.line"),
      t("shiftDetail.stationGroup.all"),
    ];
  }, [t]);

  const handleSliderChange = useCallback(
    (e: number) => {
      if (e === 3 && enableAllStations) {
        setIsLoadAllStations(true);
      }
      setStationGroupIndex(e);
    },
    [enableAllStations]
  );

  const handle418Change = useCallback((v: Weekly418Status | null) => {
    setStatus418Filter(v);
  }, []);

  const handleSendAll = useCallback(() => {
    const type = filterType[stationGroupIndex];
    sendBatch({
      shiftId: shift.id,
      sendShiftRequestParam: {
        stationIds: selectedStationIds,
        ptUserIds: userIds,
      },
    })
      .unwrap()
      .then(() => {
        setSentPtUserIds((prev) => {
          return [...prev, ...userIds];
        });
        showMessage({
          type: "success",
          title: t("shiftDetail.batchRequest.success.title"),
          message: t("shiftDetail.batchRequest.success.message", {
            count: userIds.length,
            typeText: t(`shiftDetail.batchRequest.success.stationType.${type}`),
          }),
        });
      })
      .catch((err: unknown) => {
        showError(err, "shiftDetail.request.sent.fail.title");
      });
  }, [
    sendBatch,
    stationGroupIndex,
    t,
    userIds,
    showMessage,
    shift,
    showError,
    selectedStationIds,
  ]);

  const handleConfirmSendAll = useCallback(() => {
    if (stationGroupIndex !== 3) {
      handleSendAll();
      return;
    }
    setSendConfirmDialog(true);
  }, [handleSendAll, stationGroupIndex]);

  return (
    <div>
      <ConfirmSendAllStationsDialog
        isOpen={sendConfirmDialog}
        isSelectedAllStations={
          selectedStationIds.length === defaultSelectedStationIds.length
        }
        isIncident={shift.isIncident}
        setShowDialog={setSendConfirmDialog}
        onSend={handleSendAll}
      />
      {!showPtUserList ? (
        <div
          className={cn(
            "lg:w-4/5",
            "lg:min-w-[910px]",
            "md:px-0",
            "px-4",
            "w-full",
            "text-right",
            "mx-auto",
            "mb-5"
          )}
        >
          <Button
            theme="primary"
            className={cn("w-full", "md:w-auto")}
            prefixIcon={PlusIcon}
            onClick={handleShowPtUserList}
          >
            <Trans i18nKey="shiftDetail.showSearchButton" />
          </Button>
        </div>
      ) : null}
      {showPtUserList ? (
        <section
          className={cn(
            "rounded-lg",
            "bg-white",
            "shadow",
            "lg:w-4/5",
            "lg:min-w-[910px]",
            "mx-auto"
          )}
        >
          <div className="p-6">
            <div className={cn("flex", "flex-col", "gap-5")}>
              <div className={cn("flex", "flex-row")}>
                <p className={cn("font-medium", "text-lg", "grow")}>
                  <Trans i18nKey="shiftDetail.title" />
                </p>
                <button
                  type="button"
                  className={cn(
                    "rounded-md",
                    "bg-white",
                    "text-gray-400",
                    "hover:text-gray-500",
                    "focus:outline-none",
                    "focus:ring-2",
                    "focus:ring-indigo-500",
                    "focus:ring-offset-2"
                  )}
                  onClick={handleHidePtUserList}
                >
                  <XMarkIcon className={cn("h-6", "w-6")} aria-hidden="true" />
                </button>
              </div>
              <div className={cn("w-2/3", "mx-auto")}>
                <Slider
                  labels={slideLabels}
                  min={0}
                  max={3}
                  onChange={handleSliderChange}
                  labelClassName="w-1/4"
                  className={cn("pb-20", "md:pb-2")}
                />
              </div>
              {stationGroupIndex === 3 && !enableAllStations ? (
                <p>
                  <Trans i18nKey="common.comingSoon" />
                </p>
              ) : (
                <>
                  <div>
                    <FindPTUserStationSelect
                      onUpdateSelectStations={setSelectedStationIds}
                      isLoading={isStationLoading}
                      defaultStationIds={defaultSelectedStationIds}
                      lineStations={groupedStations}
                      shouldShow={stationGroupIndex !== 0}
                    />
                  </div>
                  <div className={cn("flex", "flex-col", "gap-3")}>
                    <div>
                      <p
                        className={cn(
                          "font-medium",
                          "grow",
                          "sm:grow-0",
                          "mb-2"
                        )}
                      >
                        <Trans i18nKey="shiftDetail.description418" />
                      </p>
                      <div className={cn("flex", "flex-row", "items-center")}>
                        <Select<Weekly418Status | null>
                          className={cn(
                            "mr-3",
                            "flex-1",
                            "lg:flex-none",
                            "lg:grow"
                          )}
                          options={status418FilterOptions}
                          value={status418Filter}
                          onChange={handle418Change}
                        />
                        <SendInvitationButton
                          pendingUserIds={pendingUserIds}
                          handleSendAll={handleConfirmSendAll}
                          isBatchSendLoading={isBatchSendLoading}
                          isPartTimeListLoading={isPartTimeListLoading}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
      ) : null}
    </div>
  );
};

export default FindPTUserSection;
